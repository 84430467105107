import React, { useState } from "react";
import moment from "moment";
import { connect } from "react-redux";

import { START_LOADING, STOP_LOADING } from "../../../redux/actions/types";
import API from "../../../shared/axios";
import { showError } from "../../../redux/actions/shared";
import { ifEmpty } from "../../../shared/sharedFunctions";
import TransactionItems from "./components/TransactionItems";
import ReverseTransaction from "./components/ReverseTransaction";

const Transactions = (props) => {
  const { userId, loading } = props;
  const { startLoading, stopLoading } = props;

  const [transactionsFilters, setTransactionsFilters] = useState({
    start_date: "",
    end_date: "",
    transaction_type: "",
  });

  const { start_date, end_date, transaction_type } = transactionsFilters;

  const [baseTransactions, setBaseTransactions] = useState([]);
  const [openTransactionItems, setOpenTransactionItems] = useState(false);
  const [currentBaseTransaction, setCurrentBaseTransaction] = useState({});
  const [currentBaseTransactionIndex, setCurrentBaseTransactionIndex] =
    useState("");
  const [openReverseTransaction, setOpenReverseTransaction] = useState(false);

  const handleChange = (e) => {
    e.preventDefault();
    setTransactionsFilters({
      ...transactionsFilters,
      [e.target.name]: e.target.value,
    });
  };

  const fetchTransactions = async () => {
    if (ifEmpty(transactionsFilters)) {
      return window.alert("Indicate the transaction filters");
    }
    if (end_date < start_date) {
      return alert("End date must be greater than start date.");
    }
    startLoading();
    const body = {
      ...transactionsFilters,
    };
    await API.post(
      `/api/finance/accountant/get-transactions-data/${userId}/`,
      body
    )
      .then((res) => {
        setBaseTransactions(res?.data?.base_transactions_data);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  const handleOpenTransactionItems = (base_transaction) => {
    setCurrentBaseTransaction(base_transaction);
    setOpenTransactionItems(true);
  };

  const handleOpenReverseTransaction = (base_transaction, index) => {
    setCurrentBaseTransaction(base_transaction);
    setCurrentBaseTransactionIndex(index);
    setOpenReverseTransaction(true);
  };

  return (
    <>
      <div className="table__parent" id={loading ? "formSubmitting" : ""}>
        <div class="table__parentHeader">
          <h3>Select filters to view transactions below</h3>
          <div
            class="dialog__row"
            style={{ maxWidth: "1000px", margin: "auto" }}
          >
            <span>
              <label for="">Transaction Type</label>
              <select
                name="transaction_type"
                onChange={handleChange}
                value={transaction_type}
              >
                <option value="" disabled selected>
                  --select--
                </option>
                <option value="invoice">Invoice</option>
                <option value="journal">Journal</option>
                <option value="payment">Payment</option>
                <option value="receipt">Receipt</option>
                <option value="reversal">Reversal</option>
              </select>
            </span>
            <span>
              <label for="">Start Date</label>
              <input
                type="date"
                name="start_date"
                onChange={handleChange}
                value={start_date}
              />
            </span>
            <span>
              <label for="">End Date</label>
              <input
                type="date"
                name="end_date"
                onChange={handleChange}
                value={end_date}
              />
            </span>
            <span>
              <label for="" style={{ visibility: "hidden" }}>
                Action
              </label>
              <button type="button" onClick={fetchTransactions}>
                View Transactions
              </button>
            </span>
          </div>
        </div>
        <br />

        {baseTransactions?.length > 0 && (
          <>
            <h3 className="tc dodgerblue">Transactions Summary</h3>
            <table class="table__listing">
              <thead>
                <tr className="table__listingHeader">
                  <th>Type</th>
                  <th>Date</th>
                  <th>Memo</th>
                  <th>Amount</th>
                  <th>More Details</th>
                  <th>Reverse</th>
                </tr>
              </thead>
              <tbody>
                {baseTransactions?.map((base_transaction, index) => (
                  <tr class="table__listingItem" key={base_transaction?.id}>
                    <td>{base_transaction?.transaction_type}</td>
                    <td>
                      {moment(base_transaction?.transaction_date).format("LL")}
                    </td>
                    <td>{base_transaction?.memo}</td>
                    <td>{base_transaction?.amount}</td>
                    <td
                      className="dodgerblue bd button__sp"
                      onClick={() =>
                        handleOpenTransactionItems(base_transaction)
                      }
                    >
                      view More
                    </td>
                    <td className="red bd button__sp">
                      {base_transaction?.transaction_type === "reversal" ? (
                        <span>N/A</span>
                      ) : (
                        <span
                          onClick={() =>
                            handleOpenReverseTransaction(
                              base_transaction,
                              index
                            )
                          }
                        >
                          Reverse
                        </span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </div>
      {/* child components */}
      {openTransactionItems && (
        <TransactionItems
          openTransactionItems={openTransactionItems}
          setOpenTransactionItems={setOpenTransactionItems}
          currentBaseTransaction={currentBaseTransaction}
        />
      )}
      {openReverseTransaction && (
        <ReverseTransaction
          openReverseTransaction={openReverseTransaction}
          setOpenReverseTransaction={setOpenReverseTransaction}
          baseTransactions={baseTransactions}
          setBaseTransactions={setBaseTransactions}
          currentBaseTransaction={currentBaseTransaction}
          currentBaseTransactionIndex={currentBaseTransactionIndex}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Transactions);
