// import installed packages
import { useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";

// import styles
import "./Sidebar.scss";
// import material ui items

// import shared/global items

// import components/pages

// import redux API
import { logout } from "../../../redux/actions/auth";
import StaffLinks from "./Links/StaffLinks";
import ClientLinks from "./Links/ClientLinks";
import SystemAdminLinks from "./Links/SystemAdminLinks";
import FinanceLinks from "./Links/FinanceLinks";

const Sidebar = (props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { loggedIn, user_groups } = props;
  const { logoutUser } = props;

  const [openSubMenu, setOpenSubMenu] = useState("");

  // function to toggle sidebar submenu
  const toggleSubmenu = (link_name) => {
    if (openSubMenu === link_name) {
      setOpenSubMenu("");
    } else {
      setOpenSubMenu(link_name);
    }
  };

  return (
    <div className="left-navbar" id="nav-bar">
      <nav className="nav">
        <div className="nav__list">
          {/* unprotected links */}
          <>
            <Link
              to="/"
              className={
                `${pathname}` === "/" ? "nav__link active" : "nav__link"
              }
            >
              <i class="bx bx-home"></i>
              <span className="nav__name">Home</span>
            </Link>
          </>
          {/* protected links */}
          {loggedIn && (
            <>
              {/* <Link
                to="/dashboard/"
                className={
                  `${pathname}` === "/dashboard/"
                    ? "nav__link active"
                    : "nav__link"
                }
              >
                <i className="bx bx-grid-alt nav__icon"></i>
                <span className="nav__name">Dashboard</span>
              </Link> */}
              {/* staff links */}
              {["Staff", "System Admin"]?.some((allowed_group) =>
                user_groups?.includes(allowed_group)
              ) && (
                <>
                  <StaffLinks
                    Link={Link}
                    pathname={pathname}
                    toggleSubmenu={toggleSubmenu}
                    openSubMenu={openSubMenu}
                  />
                </>
              )}
              {/* system admin links */}
              {["System Admin"]?.some((allowed_group) =>
                user_groups?.includes(allowed_group)
              ) && (
                <>
                  <SystemAdminLinks
                    Link={Link}
                    pathname={pathname}
                    toggleSubmenu={toggleSubmenu}
                    openSubMenu={openSubMenu}
                  />
                </>
              )}
              {["Client"]?.some((allowed_group) =>
                user_groups?.includes(allowed_group)
              ) && (
                <>
                  <ClientLinks
                    Link={Link}
                    pathname={pathname}
                    toggleSubmenu={toggleSubmenu}
                    openSubMenu={openSubMenu}
                  />
                </>
              )}
              {["Finance", "System Admin"]?.some((allowed_group) =>
                user_groups?.includes(allowed_group)
              ) && (
                <>
                  <FinanceLinks
                    Link={Link}
                    pathname={pathname}
                    toggleSubmenu={toggleSubmenu}
                    openSubMenu={openSubMenu}
                  />
                </>
              )}
              <Link
                to="/profile/"
                className={
                  `${pathname}` === "/profile/"
                    ? "nav__link active"
                    : "nav__link"
                }
              >
                <i className="bx bx-user nav__icon"></i>
                <span className="nav__name">Profile</span>
              </Link>
              <Link
                to=""
                className="nav__link"
                onClick={() => logoutUser(navigate)}
              >
                <i className="bx bx-log-out-circle"></i>
                <span className="nav__name">Logout</span>
              </Link>
            </>
          )}
        </div>
      </nav>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user_groups: state?.auth?.user?.user_groups,
    loggedIn: state.auth?.loggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: (navigate) => dispatch(logout(navigate)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
