import React, { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { connect } from "react-redux";
import { get_saas_clients } from "../../../../redux/actions/saas_clients";
import AddClient from "./components/AddClient";
import AddClientContact from "./components/AddClientContact";
import ClientLicenses from "./components/ClientLicenses";
import { formatWithCommas } from "../../../../shared/sharedFunctions";
import EditClient from "./components/EditClient";

const Clients = (props) => {
  const { userId, loading, clients } = props;
  const { getSaasClients } = props;

  const [openAddClient, setOpenAddClient] = useState(false);
  const [openEditClient, setOpenEditClient] = useState(false);
  const [openAddClientContact, setOpenAddClientContact] = useState(false);
  const [currentClient, setCurrentClient] = useState({});
  const [openClientLicenses, setOpenClientLicenses] = useState(false);

  // useeffect to get all saas clients
  useEffect(() => {
    if (userId) {
      getSaasClients(userId);
    }
  }, [userId, getSaasClients]);

  const handleOpenAddClientContact = (client) => {
    setCurrentClient(client);
    setOpenAddClientContact(true);
  };

  const handleOpenClientLicenses = (client) => {
    setCurrentClient(client);
    setOpenClientLicenses(true);
  };

  const handleOpenEditClient = (client) => {
    setCurrentClient(client);
    setOpenEditClient(true);
  };

  return (
    <>
      <div className="table__parent" id={loading ? "pageSubmitting" : ""}>
        <div class="table__parentHeader">
          <button type="button" onClick={() => setOpenAddClient(true)}>
            New Client
          </button>
          <h3>Maintain Saas Clients</h3>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        {clients?.length > 0 ? (
          <table class="table__listing">
            <thead>
              <tr class="table__listingHeader">
                <th>Client Name</th>
                <th>Client Type</th>
                <th>Monthly Rate (KES)</th>
                <th>Contact Person</th>
                <th>Licenses</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              {clients?.map((client) => (
                <tr class="table__listingItem" key={client?.id}>
                  <td>{client?.company_name}</td>
                  <td>{client?.client_type}</td>
                  <td>{formatWithCommas(client?.monthly_rate)}</td>
                  <td>
                    {client?.contact_person ? (
                      <span>{client?.contact_person}</span>
                    ) : (
                      <span
                        className="dodgerblue bd button__sp"
                        onClick={() => handleOpenAddClientContact(client)}
                      >
                        Add
                      </span>
                    )}
                  </td>
                  <td
                    className="dodgerblue bd button__sp"
                    onClick={() => handleOpenClientLicenses(client)}
                  >
                    view
                  </td>
                  <td
                    className="button__sp dodgerblue bd"
                    onClick={() => handleOpenEditClient(client)}
                  >
                    Edit
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <h4 class="not__available">No clients available</h4>
        )}
      </div>
      {/* child components */}
      {openAddClient && (
        <AddClient
          openAddClient={openAddClient}
          setOpenAddClient={setOpenAddClient}
        />
      )}
      {openAddClientContact && (
        <AddClientContact
          openAddClientContact={openAddClientContact}
          setOpenAddClientContact={setOpenAddClientContact}
          currentClient={currentClient}
        />
      )}
      {openClientLicenses && (
        <ClientLicenses
          openClientLicenses={openClientLicenses}
          setOpenClientLicenses={setOpenClientLicenses}
          currentClient={currentClient}
        />
      )}
      {openEditClient && (
        <EditClient
          openEditClient={openEditClient}
          setOpenEditClient={setOpenEditClient}
          currentClient={currentClient}
          setCurrentClient={setCurrentClient}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
    clients: state?.saas_clients?.clients,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSaasClients: (userId) => dispatch(get_saas_clients(userId)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Clients);
