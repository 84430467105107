import React from "react";
import { connect } from "react-redux";
import MinDialog from "../../../../../components/common/MinDialog";
import { START_LOADING } from "../../../../../redux/actions/types";
import CircularProgress from "@mui/material/CircularProgress";
import { ifEmpty } from "../../../../../shared/sharedFunctions";
import { edit_saas_client } from "../../../../../redux/actions/saas_clients";

const EditClient = (props) => {
  const { openEditClient, userId, loading, currentClient } = props;
  const { setOpenEditClient, startLoading, editSaasClient, setCurrentClient } =
    props;

  const { company_name, client_type, access_url, monthly_rate } = currentClient;

  const handleChange = (e) => {
    setCurrentClient({ ...currentClient, [e.target.name]: e.target.value });
  };

  const handleClose = () => {
    setOpenEditClient(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (ifEmpty(currentClient)) {
      return window.alert("All fields are required");
    }
    startLoading();
    editSaasClient(userId, currentClient);
  };

  return (
    <MinDialog isOpen={openEditClient}>
      <div class="dialog" id={loading ? "formSubmitting" : ""}>
        <h3>New client details</h3>
        <div class="dialog__rowSingleItem">
          <label for="">Name</label>
          <input
            type="text"
            name="company_name"
            onChange={handleChange}
            value={company_name}
          />
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div class="dialog__rowSingleItem">
          <label for="">Client Type</label>
          <select
            name="client_type"
            onChange={handleChange}
            value={client_type}
          >
            <option value="" disabled selected>
              --select--
            </option>
            <option value="school">School</option>
          </select>
        </div>
        <div class="dialog__rowSingleItem">
          <label for="">Access Url</label>
          <input
            type="url"
            name="access_url"
            onChange={handleChange}
            value={access_url}
          />
        </div>
        <div class="dialog__rowSingleItem">
          <label for="">Monthly Rate</label>
          <input
            type="number"
            name="monthly_rate"
            onChange={handleChange}
            value={monthly_rate}
            min="1"
          />
        </div>
        <div class="form__Buttons">
          <button type="button" onClick={handleClose}>
            Close
          </button>
          <button type="button" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    editSaasClient: (userId, body) => dispatch(edit_saas_client(userId, body)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditClient);
