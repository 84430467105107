// import installed packages
import { useRef } from "react";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
// import styles
import "./TrialBalanceTemplate.scss";
// import material ui items
// import shared/global items

// import components/pages
import MaxDialog from "../../../../../components/common/MaxDialog";
// import redux API

const TrialBalanceTemplate = (props) => {
  const { companyName, openReportTemplate, start_date, end_date, reportData } =
    props; // get state from props
  const { setOpenReportTemplate } = props; // get dispatch actions from props

  // printing and saving report
  const printArea = useRef();
  const handlePrint = useReactToPrint({
    content: () => printArea.current,
    documentTitle: "Trial Balance Report",
  });

  const handleClose = (e) => {
    setOpenReportTemplate(false);
  };

  return (
    <MaxDialog isOpen={openReportTemplate} maxWidth="1400px" maxHeight="90vh">
      <div className="dialog">
        <table
          className="report__statement"
          border="1"
          rules="rows"
          ref={printArea}
        >
          <thead>
            <tr>
              <th colSpan="8" className="dodgerblue">
                {companyName}
              </th>
            </tr>
            <tr>
              <th colSpan="8" className="dodgerblue">
                Trial Balance
              </th>
            </tr>
            <tr>
              <th colSpan="8" className="dodgerblue">
                From {moment(start_date).format("LL")} to{" "}
                {moment(end_date).format("LL")}
              </th>
            </tr>
            <tr>
              {/* top level containing titles */}
              <th colSpan="2" className="tl purple">
                Details
              </th>
              <th colSpan="2" className="purple">
                Opening
              </th>
              <th colSpan="2" className="purple">
                Transactions
              </th>
              <th colSpan="2" className="purple">
                Closing
              </th>
            </tr>
            <tr>
              {/* debit and credit section */}
              <th colSpan="2"></th>
              <th colSpan="1" className="bbottom purple">
                Dr
              </th>
              <th colSpan="1" className="bbottom purple">
                Cr
              </th>
              <th colSpan="1" className="bbottom purple">
                Dr
              </th>
              <th colSpan="1" className="bbottom purple">
                Cr
              </th>
              <th colSpan="1" className="bbottom purple">
                Dr
              </th>
              <th colSpan="1" className="bbottom purple">
                Cr
              </th>
            </tr>
          </thead>

          <tbody>
            {/* ledgers looping section */}
            <LedgersLooping
              parentLedgersListing={reportData?.report_data_listing}
            />
            <tr>
              <td></td>
            </tr>
            {/* retained earnings section */}
            <tr className="ledger_group">
              <th colSpan="2">Retained Earnings</th>
              {/* if retained earnings is of type Debit show a debit opening else 0 */}
              <td className="tc">
                {reportData?.overall_totals?.retainedEarnings?.type === "Debit"
                  ? reportData?.overall_totals?.retainedEarnings?.amount?.toLocaleString()
                  : 0}
              </td>
              {/* if retained earnings is of type credit show a credit opening else 0 */}
              <td className="tc">
                {reportData?.overall_totals?.retainedEarnings?.type === "Credit"
                  ? reportData?.overall_totals?.retainedEarnings?.amount?.toLocaleString()
                  : ""}
              </td>
              <td className="tc"></td>
              <td className="tc"></td>
              {/* if retained earnings is less than zero show a debit closing */}
              <td className="tc">
                {reportData?.overall_totals?.retainedEarnings?.type === "Debit"
                  ? reportData?.overall_totals?.retainedEarnings?.amount?.toLocaleString()
                  : ""}
              </td>
              {/* if retained earnings is greater than zero show a credit closing */}
              <td className="tc">
                {reportData?.overall_totals?.retainedEarnings?.type === "Credit"
                  ? reportData?.overall_totals?.retainedEarnings?.amount?.toLocaleString()
                  : ""}
              </td>
            </tr>
            <tr>
              <td></td>
            </tr>
            <br />
            {/* totals section */}
            <tr className="parent_ledger_groups_totals">
              <th className="" colSpan="2">
                Totals
              </th>
              <th className="amount">
                {reportData?.overall_totals?.openingDebitTotals?.toLocaleString()}
              </th>
              <th className="amount">
                {reportData?.overall_totals?.openingCreditTotals?.toLocaleString()}
              </th>
              <th className="amount">
                {reportData?.overall_totals?.currentPeriodDebitTotals?.toLocaleString()}
              </th>
              <th className="amount">
                {reportData?.overall_totals?.currentPeriodCreditTotals?.toLocaleString()}
              </th>
              <th className="amount">
                {reportData?.overall_totals?.closingDebitTotals?.toLocaleString()}
              </th>
              <th className="amount">
                {reportData?.overall_totals?.closingCreditTotals?.toLocaleString()}
              </th>
            </tr>
          </tbody>
        </table>
        <div className="form__Buttons">
          <button type="button" onClick={handleClose}>
            Close
          </button>
          <button type="button" onClick={handlePrint}>
            Print
          </button>
        </div>
      </div>
    </MaxDialog>
  );
};

export default TrialBalanceTemplate;

const LedgersLooping = ({ parentLedgersListing }) => {
  return (
    <>
      {parentLedgersListing?.map((parentLedgerGroup) => (
        <>
          <tr className="parent_ledger_group">
            <th colSpan="8">{parentLedgerGroup?.name}</th>
          </tr>
          {parentLedgerGroup?.ledgerGroupsListing?.map((ledgerGroup) => (
            <>
              <tr className="ledger_group">
                <th>{ledgerGroup?.name}</th>
              </tr>
              {ledgerGroup?.ledgersListing?.map((ledger) => (
                <>
                  <tr className="ledger">
                    <td colSpan="2">{ledger?.name}</td>
                    <td className="amount">
                      {ledger?.openingDebits?.toLocaleString()}
                    </td>
                    <td className="amount">
                      {ledger?.openingCredits?.toLocaleString()}
                    </td>
                    <td className="amount">
                      {ledger?.currentPeriodDebits?.toLocaleString()}
                    </td>
                    <td className="amount">
                      {ledger?.currentPeriodCredits?.toLocaleString()}
                    </td>
                    <td className="amount">
                      {ledger?.closingDebits?.toLocaleString()}
                    </td>
                    <td className="amount">
                      {ledger?.closingCredits?.toLocaleString()}
                    </td>
                  </tr>
                </>
              ))}
            </>
          ))}
          <br />
        </>
      ))}
    </>
  );
};
