import { CircularProgress } from "@mui/material";
import React, { useState } from "react";
import { connect } from "react-redux";
import MinDialog from "../../../../components/common/MinDialog";
import { showError } from "../../../../redux/actions/shared";
import { START_LOADING, STOP_LOADING } from "../../../../redux/actions/types";
import API from "../../../../shared/axios";
import { ifEmpty } from "../../../../shared/sharedFunctions";

const ReverseTransaction = (props) => {
  const {
    baseTransactions,
    userId,
    openReverseTransaction,
    loading,
    currentBaseTransaction,
    currentBaseTransactionIndex,
  } = props;
  const {
    startLoading,
    stopLoading,
    setBaseTransactions,
    setOpenReverseTransaction,
  } = props;

  const [reversalDetails, setReversalDetails] = useState({
    reversalReason: "",
    transaction_date: "", // which is the reversal date
  });

  const { reversalReason, transaction_date } = reversalDetails;

  const handleChange = (e) => {
    setReversalDetails({ ...reversalDetails, [e.target.name]: e.target.value });
  };

  const handleReverseTransaction = async (e) => {
    e.preventDefault();
    if (ifEmpty(reversalDetails)) {
      return window.alert("You must indicate the reversal reason and date");
    }
    const body = {
      ...currentBaseTransaction,
      ...reversalDetails,
    };
    startLoading();
    await API.post(`/api/finance/reverse-transaction/${userId}/`, body)
      .then((res) => {
        const arrayToMutate = [...baseTransactions];
        arrayToMutate[currentBaseTransactionIndex] =
          res?.data?.reversed_base_transaction;
        setBaseTransactions(arrayToMutate);
        window.alert(res?.data?.detail);
        setOpenReverseTransaction(false);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };
  return (
    <MinDialog isOpen={openReverseTransaction}>
      <div class="dialog" id={loading ? "formSubmitting" : ""}>
        <h3>Enter reason for reversing this transaction</h3>
        <div class="dialog__rowSingleItem">
          <label for="">Reversal Date</label>
          <input
            type="date"
            name="transaction_date"
            onChange={handleChange}
            value={transaction_date}
          />
        </div>
        <div class="dialog__rowSingleItem">
          <label for="">Reversal reason</label>
          {loading && (
            <CircularProgress
              style={{ position: "absolute", marginLeft: "45%" }}
            />
          )}
          <textarea
            name="reversalReason"
            onChange={handleChange}
            value={reversalReason}
          />
        </div>
        <div class="form__Buttons">
          <button
            type="button"
            onClick={() => setOpenReverseTransaction(false)}
          >
            Close
          </button>
          <button type="button" onClick={handleReverseTransaction}>
            Reverse
          </button>
        </div>
      </div>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReverseTransaction);
