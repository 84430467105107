// import installed packages
import { useRef } from "react";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
// import styles
// import material ui items
// import shared/global items
// import components/pages
import MaxDialog from "../../../../../components/common/MaxDialog";
// import redux API

const BalanceSheet = (props) => {
  const { openReportTemplate, end_date, companyName, reportData } = props; // get state from props
  const { setOpenReportTemplate } = props; // get dispatch actions from props

  // printing and saving report
  const printArea = useRef();
  const handlePrint = useReactToPrint({
    content: () => printArea.current,
    documentTitle: "Balance Sheet Report",
  });

  const handleClose = () => {
    setOpenReportTemplate(false);
  };

  return (
    <MaxDialog isOpen={openReportTemplate} maxWidth="1200px" maxHeight="90vh">
      <div className="dialog">
        <table
          className="report__statement"
          border="1"
          rules="rows"
          ref={printArea}
        >
          <thead>
            <tr>
              <th colSpan="8" className="dodgerblue">
                {companyName}
              </th>
            </tr>
            <tr>
              <th colSpan="8" className="dodgerblue">
                Balance Sheet
              </th>
            </tr>
            <tr>
              <th colSpan="8" className="dodgerblue">
                As at {moment(end_date).format("LL")}
              </th>
            </tr>
            <tr>
              <th className="bbottom purple tl">Details</th>
              <th className="bbottom purple">Amount (Kshs)</th>
            </tr>
          </thead>

          <tbody>
            {/* we show the assets first */}
            <LedgersLooping
              parentLedgersListing={reportData?.report_data_assets}
            />

            {/* we show the total assets here */}
            <tr className="parent_ledger_groups_totals">
              <th>Total Assets</th>
              <th className="amount">
                {reportData?.overall_totals?.overallAssetsTotals?.toLocaleString()}
              </th>
            </tr>

            {/* we then show the liabilities */}
            <LedgersLooping
              parentLedgersListing={
                reportData?.report_data_capital_and_liabilities
              }
            />
            <tr>
              <th className="tl">Retained Earnings</th>
              <td className="amount">
                {reportData?.overall_totals?.retainedEarnings?.toLocaleString()}
              </td>
            </tr>
            {/* we show total capital and liabilities */}
            <tr className="parent_ledger_groups_totals">
              <th>Total Capital &amp; Liabilities</th>
              <th className="amount">
                {reportData?.overall_totals?.overallCapitalAndLiabilitiesTotals?.toLocaleString()}
              </th>
            </tr>
          </tbody>
        </table>
        <div className="form__Buttons">
          <button type="button" onClick={handleClose}>
            Close
          </button>
          <button type="button" onClick={handlePrint}>
            Print
          </button>
        </div>
      </div>
    </MaxDialog>
  );
};

export default BalanceSheet;

// we reuse this to loop for assets, capital and liabilities

const LedgersLooping = ({ parentLedgersListing }) => {
  return (
    <>
      {parentLedgersListing?.map((parentLedgerGroup) => (
        <>
          <tr className="parent_ledger_group">
            <th>{parentLedgerGroup?.name}</th>
          </tr>
          {parentLedgerGroup?.ledgerGroupsListing?.map((ledgerGroup) => (
            <>
              <tr className="ledger_group">
                <th>{ledgerGroup?.name}</th>
              </tr>
              {ledgerGroup?.ledgersListing?.map((ledger) => (
                <>
                  <tr className="ledger">
                    <td>{ledger?.name}</td>
                    <td className="amount">
                      {ledger?.netAmount?.toLocaleString()}
                    </td>
                  </tr>
                </>
              ))}
            </>
          ))}
        </>
      ))}
    </>
  );
};
