// import installed packages
import React, { useEffect } from "react";
// import styles
import "./AboutUs.scss";
// import material ui items
// import shared/global items
// import components/pages
// import redux API

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="about__us">
      <div className="first__row">
        <h3>Our Mission</h3>
        <p>
          To use software technology to bring the best in people and businesses
          to benefit and enhance human progress
        </p>
        <h3>Our Vision</h3>
        <p>To become the software solution partner of choice in Africa.</p>
      </div>
      <div className="second__row">
        <h2>Our Values</h2>
        <h3>Focus on delivering quality value.</h3>
        <p>We aim to deliver the best value to fulfill your requirements.</p>
        <h3>Grow &amp; innovate continously</h3>
        <p>
          Through continous skills development, we aim to continously innovate
          to always offer you the best solution.
        </p>
        <h3>Build software with integrity.</h3>
        <p>
          We cherish to build software that preserves our integrity and our
          client's.
        </p>
        <h3>Build software for a better world</h3>
        <p>
          We aim to offer software solutions to benefit humanity and enhance
          human progress.
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
