import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { OPEN_LOGIN } from "../../../redux/actions/types";

import "./RenewLicense.scss";

//   THIS IS THE REDIRECT PAGE FROM Saas client domain to azw group FOR LOGGED OUT USERS, once a user logs in, they are redirected to my licenses page
const RenewLicense = (props) => {
  const navigate = useNavigate();
  const { userId } = props;
  const { openLogin } = props;

  // if userId redirect to my licenses page
  useEffect(() => {
    if (userId) {
      navigate(`/clients/my-licenses/`);
    }
  }, [userId, navigate]);

  return (
    <div className="renew__license">
      <h3>Login to access your licenses</h3>
      <button type="button" onClick={openLogin}>
        Login Now
      </button>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openLogin: () => dispatch({ type: OPEN_LOGIN }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RenewLicense);
