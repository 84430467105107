// import installed packages
import React, { useEffect } from "react";
// import styles
import "./ContactUs.scss";
// import material ui items
// import shared/global items
// import components/pages
// import redux API

const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
  };
  return (
    <div className="contact__us">
      <h2>Contact Us</h2>
      <form onSubmit={handleSubmit}>
        <h3>Enter your inquiry below or write to us via info@azwgroup.com</h3>
        <label htmlFor="">Your Name</label>
        <input type="text" name="" />
        <label htmlFor="">Your Email</label>
        <input type="email" name="" />
        <label htmlFor="">Your Inquiry</label>
        <textarea name="" id="" cols="30" rows="10" />
        <div className="form__Buttons">
          <button type="submit">Submit</button>
        </div>
      </form>
    </div>
  );
};

export default ContactUs;
