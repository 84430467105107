// import installed packages
import React from "react";
// import styles
import "./Featured.scss";
// import material ui items
// import shared/global items
import web_app from "../../../../images/web_app.png";
import mobile_app from "../../../../images/mobile_app.jpg";
import it_consultancy from "../../../../images/it_consultancy.png";
import business_integration from "../../../../images/business_integration.png";
// import components/pages
import HomeCard from "../HomeCard/HomeCard";
// import redux API

const Featured = () => {
  return (
    <div className="featured">
      <h3>Featured Services.</h3>
      <h2>Building software to make the world better.</h2>
      <div className="featured__cards">
        <HomeCard
          title="Web apps"
          description="You get a customizable web app to match your requirements and fit your purpose"
          image={web_app}
        />
        <HomeCard
          title="Mobile apps"
          description="You get a tailored mobile app to serve your purpose according to the specifications you give"
          image={mobile_app}
        />
        <HomeCard
          title="IT Consultancy"
          description="You get all the information you need to make wise decisions when considering adoption or implementation of an IT Project or System"
          image={it_consultancy}
        />
        <HomeCard
          title="Business Technology Integration"
          description="You get the best service for integrating a technology solution to your business, taking into consideration all the factors affecting your integration"
          image={business_integration}
        />
      </div>
    </div>
  );
};

export default Featured;
