// import installed packages
import React, { useEffect } from "react";
import { connect } from "react-redux";
// import styles
// import material ui items
// import shared/global items
// import components/pages
import EmailsListing from "./components/EmailsListing";
// import redux API
import { get_my_sent_or_received_emails } from "../../redux/actions/emails";
import { START_LOADING } from "../../redux/actions/types";

const Sent = (props) => {
  const { sent, userId } = props;
  const { startLoading, getMySentOrReceivedEmails } = props;

  useEffect(() => {
    startLoading();
    getMySentOrReceivedEmails(userId);
  }, [getMySentOrReceivedEmails, startLoading, userId]);

  return (
    <div className="inbox">
      {sent?.length > 0 ? (
        <EmailsListing emails={sent} />
      ) : (
        <h4 className="not__available">No emails sent yet</h4>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    sent: state?.emails?.sent,
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    getMySentOrReceivedEmails: (userId) =>
      dispatch(get_my_sent_or_received_emails(userId, "sent")),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sent);
