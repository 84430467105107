// import installed packages
import React, { useEffect } from "react";
// import styles
import "./MobileApps.scss";
// import material ui items
// import shared/global items
import mobile_app_idea from "../../../images/mobile_app_idea.jpg";
// import components/pages
// import redux API

const MobileApps = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="mobile__apps">
      <div className="first__row">
        <h2>Bring your idea into life with a customized mobile app.</h2>
        <img src={mobile_app_idea} alt="" />
      </div>
      <div className="second__row">
        <h2>How the process flows.</h2>
        <h3>Requirements Gathering</h3>
        <ul>
          <li>
            At this stage, we engage with you to note down how you want the
            application to operate, including the features, processes, etc.
          </li>
        </ul>
        <h3>Planning</h3>
        <ul>
          <li>
            At this stage, we lay out the plan to follow in order to deliver on
            your requirements.
          </li>
        </ul>
        <h3>Design</h3>
        <ul>
          <li>
            Here, we design a mock-up to illustrate how the final product will
            look like, thus allowing you to offer feedback as to the adjustments
            you wish incorporated before proceeding to development.
          </li>
        </ul>
        <h3>Coding</h3>
        <ul>
          <li>
            Once the design is satisfactory to you, we proceed with writing code
            for the application.
          </li>
        </ul>
        <h3>Testing</h3>
        <ul>
          <li>
            Before final release, we conduct app testing to confirm the product
            works as expected.
          </li>
        </ul>
        <h3>Deployment</h3>
        <ul>
          <li>
            Once the product tests are successful, we proceed to deploy your app
            so your clients can be able to access and start using it.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MobileApps;
