import React from "react";

import "./FunzoLetu.scss";
import school_system from "../../../images/school_system.jpg";

const FunzoLetu = () => {
  return (
    <div className="funzo">
      <div className="banner">
        <div className="banner__left">
          <h2>School management with a click.</h2>
          <p>
            {" "}
            A system to serve school management, teachers, students, parents and
            school staff.
          </p>
          <a href="https://funzoletu.azwgroup.com/" target="__blank">
            Explore More
          </a>
        </div>
        <div className="banner__right">
          <img src={school_system} alt="" />
        </div>
      </div>
      <div className="benefits">
        <h3>Funzo Letu Benefits</h3>
        <section className="section">
          <h4>Students:</h4>
          <ul>
            <li> Attend online classes</li>
            <li>Do online tests</li>
            <li>Access assignments online</li>
          </ul>
        </section>
        <section className="section">
          <h4>Parents</h4>
          <ul>
            <li>Access children fees statements</li>
            <li>Access children exam results</li>
          </ul>
        </section>
        <section className="section">
          <h4>Teachers</h4>
          <ul>
            <li>Teach online classes</li>
            <li>Mark attendance online</li>
            <li>Set online tests</li>
            <li>Post students exam results online</li>
            <li>Apply leave online</li>
            <li>Post assignments for students to access online</li>
          </ul>
        </section>
        <section className="section">
          <h4>Other School Staff</h4>
          <ul>
            <li>Apply leave online</li>
          </ul>
        </section>
        <section className="section">
          <h4>Finance</h4>
          <ul>
            <li>Invoice students</li>
            <li>Receive student fees</li>
            <li>Maitain accounting records</li>
            <li>Generate financial reports</li>
          </ul>
        </section>
        <section className="section">
          <h4>Management</h4>
          <ul>
            <li>Maintain users i.e students, teachers, parents and staff</li>
            <li>Maintain staff leaves online</li>
            <li>Staff attendance monitoring</li>
          </ul>
        </section>
      </div>
    </div>
  );
};

export default FunzoLetu;
