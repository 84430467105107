// import installed packages
import React from "react";
// import styles
import "./MoreInfo.scss";
// import material ui items
// import shared/global items
import saas from "../../../../images/saas.jpg";
// import components/pages
// import redux API

const MoreInfo = () => {
  return (
    <div className="more__info">
      <div>
        <div className="more__infoLeft">
          <img src={saas} alt="" />
        </div>
        <div className="more__infoRight">
          <h3>Who we are.</h3>
          <h2>We provide modern software solutions to solve our clients.</h2>
          <p>
            Our vision is to be your go-to software solution, to take off your
            burden of software technology, so that you can focus on building and
            scaling your business, without having to worry about your software
            solutions.
          </p>
        </div>
      </div>
    </div>
  );
};

export default MoreInfo;
