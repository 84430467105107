// import installed packages
import { useEffect } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// import styles
import "./App.scss";
// import material ui items

// import shared/global items
import PrivateRoute from "./shared/PrivateRoute";
// import components/pages
import Header from "./components/common/Header/Header";
// import Footer from "./components/common/Footer";
import Home from "./pages/Home/Home";
// import Dashboard from "./pages/Dashboard/Dashboard";
import ActivateAccount from "./pages/ActivateAccount/ActivateAccount";
import ResetPasswordConfirm from "./pages/ResetPasswordConfirm";
import NotFound from "./pages/NotFound/NotFound";
import Profile from "./pages/Profile/Profile";
import Footer from "./components/common/Footer/Footer";
import MobileApps from "./pages/Services/MobileApps/MobileApps";
import WebApps from "./pages/Services/WebApps/WebApps";
import AboutUs from "./pages/AboutUs/AboutUs";
import Courzehub from "./pages/OurProducts/Courzehub/Courzehub";
import Gamefans from "./pages/OurProducts/Gamefans/Gamefans";
import Mafunzo from "./pages/OurProducts/Mafunzo/Mafunzo";
import FunzoLetu from "./pages/OurProducts/FunzoLetu/FunzoLetu";
import ContactUs from "./pages/ContactUs/ContactUs";
import Sidebar from "./components/common/Sidebar/Sidebar";
import InboxEmails from "./pages/Emails/InboxEmails";
import SentEmails from "./pages/Emails/SentEmails";
import Accounting from "./pages/Finance/Accounting/Accounting";
import Reports from "./pages/Finance/Reports/Reports";
import Transactions from "./pages/Finance/Transactions/Transactions";

// import redux API
import { get_user } from "./redux/actions/auth";
import Clients from "./pages/Staff/SystemAdmin/Clients/Clients";
import MyLicenses from "./pages/Clients/MyLicenses/MyLicenses";
import RenewLicense from "./pages/Clients/RenewLicense/RenewLicense";
import ComposeEmail from "./pages/Emails/components/ComposeEmail";

function App(props) {
  const session_cookie = localStorage.getItem("session_cookie");

  const { loggedIn, user_groups, openComposeEmailStatus } = props;
  const { getUser } = props;

  useEffect(() => {
    // get user on page refresh
    if (session_cookie) {
      getUser();
    }
  }, [session_cookie, getUser]);

  return (
    <div id="body-pd">
      <Router>
        <Header />
        {loggedIn && <Sidebar />}
        <div style={{ minHeight: "85vh" }}>
          <Routes>
            {/* unauthenticated routes */}
            <Route exact path="/" element={<Home />} />
            <Route
              exact
              path="/services/mobile-apps/"
              element={<MobileApps />}
            />
            <Route exact path="/services/web-apps/" element={<WebApps />} />
            <Route exact path="/about/about-us/" element={<AboutUs />} />
            <Route exact path="/products/courzehub/" element={<Courzehub />} />
            <Route exact path="/products/gamefans/" element={<Gamefans />} />
            <Route exact path="/products/mafunzo/" element={<Mafunzo />} />
            <Route exact path="/products/funzo-letu/" element={<FunzoLetu />} />
            <Route exact path="/contact/contact-us/" element={<ContactUs />} />
            <Route
              exact
              path="/user/password-reset/:password_token/"
              element={<ResetPasswordConfirm />}
            />
            <Route
              exact
              path="/user/activate/:activation_token/"
              element={<ActivateAccount />}
            />
            <Route
              exact
              path="/renew-saas-license/"
              element={<RenewLicense />}
            />
            {/* authenticated routes */}
            <Route exact path="/" element={<PrivateRoute />}>
              <Route exact path="/profile" element={<Profile />} />
              {/* <Route exact path="/dashboard/" element={<Dashboard />} /> */}

              {["System Admin"]?.some((allowed_group) =>
                user_groups?.includes(allowed_group)
              ) && (
                <>
                  <Route exact path="/saas-clients/" element={<Clients />} />
                </>
              )}
              {["Staff", "System Admin"]?.some((allowed_group) =>
                user_groups?.includes(allowed_group)
              ) && (
                <>
                  <Route
                    exact
                    path="/emails/inbox/"
                    element={<InboxEmails />}
                  />
                  <Route exact path="/emails/sent/" element={<SentEmails />} />
                </>
              )}
              {/* clients routes */}
              {["Client"]?.some((allowed_group) =>
                user_groups?.includes(allowed_group)
              ) && (
                <>
                  <Route
                    exact
                    path="/clients/my-licenses/"
                    element={<MyLicenses />}
                  />
                </>
              )}
              {/* finance routes */}
              {["Finance", "System Admin"]?.some((allowed_group) =>
                user_groups?.includes(allowed_group)
              ) && (
                <>
                  <Route
                    exact
                    path="/finance/accounting/"
                    element={<Accounting />}
                  />
                  <Route exact path="/finance/reports/" element={<Reports />} />
                  <Route
                    exact
                    path="/finance/transactions/"
                    element={<Transactions />}
                  />
                </>
              )}
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
        <Footer />
        {/* child components */}
        {openComposeEmailStatus?.isOpen && <ComposeEmail />}
      </Router>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loggedIn: state?.auth?.loggedIn,
    user_groups: state?.auth?.user?.user_groups,
    openComposeEmailStatus: state?.emails?.openComposeEmailStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUser: () => dispatch(get_user()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
