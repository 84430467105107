import React from "react";
import { connect } from "react-redux";
import MinDialog from "../../../../components/common/MinDialog";
import { START_LOADING } from "../../../../redux/actions/types";

import CircularProgress from "@mui/material/CircularProgress";
import { edit_ledger_group } from "../../../../redux/actions/finance";

const EditLedgerGroup = (props) => {
  const {
    currentLedgerItem,
    openEditLedgerGroup,
    parent_ledger_groups,
    loading,
    userId,
  } = props;
  const {
    setCurrentLedgerItem,
    setOpenEditLedgerGroup,
    editLedgerGroup,
    startLoading,
  } = props;

  const { name, parent_ledger_group } = currentLedgerItem;

  const handleChange = (e) => {
    setCurrentLedgerItem({
      ...currentLedgerItem,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (name?.trim() === "" || parent_ledger_group?.trim() === "") {
      return window.alert("Fields cannot be empty");
    }
    startLoading();
    const body = {
      ...currentLedgerItem,
    };
    editLedgerGroup(userId, body);
  };

  return (
    <MinDialog isOpen={openEditLedgerGroup}>
      <div class="dialog" id={loading ? "formSubmitting" : ""}>
        <h3>Edit ledger group</h3>
        <div class="dialog__rowSingleItem">
          <label for="">Parent Ledger Group</label>
          <select
            name="parent_ledger_group"
            onChange={handleChange}
            value={parent_ledger_group}
          >
            <option value="" disabled>
              --select--
            </option>
            {parent_ledger_groups?.map((parent_ledger_group) => (
              <option
                value={parent_ledger_group?.id}
                key={parent_ledger_group?.id}
              >
                {parent_ledger_group?.name}
              </option>
            ))}
          </select>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div class="dialog__rowSingleItem">
          <label for="">Ledger Group Name</label>
          <input type="text" name="name" value={name} onChange={handleChange} />
        </div>
        <div class="form__Buttons">
          <button type="button" onClick={() => setOpenEditLedgerGroup(false)}>
            Close
          </button>
          <button type="submit" onClick={handleSubmit}>
            Update
          </button>
        </div>
      </div>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
    parent_ledger_groups: state.finance?.parent_ledger_groups,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    editLedgerGroup: (userId, body) =>
      dispatch(edit_ledger_group(userId, body)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditLedgerGroup);
