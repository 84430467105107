// import installed packages
import React, { useEffect } from "react";
// import styles
import "./Gamefans.scss";
// import material ui items
// import shared/global items
import game_fans from "../../../images/game_fans.jpg";
// import components/pages
// import redux API

const Gamefans = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="game__fans">
      <div className="first__row">
        <img src={game_fans} alt="" />
        <h2>Watch live games and chat with fellow fans in the game.</h2>
      </div>
      <div className="second__row">
        <h2>Game Fans Features</h2>
        <h3>Watch live games free</h3>
        <ul>
          <li>Watch all your favorite games for free online.</li>
        </ul>
        <h3>Chat with fellow fans.</h3>
        <ul>
          <li>Interact and chat with your fellow team fans.</li>
        </ul>
      </div>
    </div>
  );
};

export default Gamefans;
