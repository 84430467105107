import API from "../shared/axios";
// user routes

// signup
export const signupUser = (newUser) => API.post("/api/user/signup/", newUser);
// activate user account
export const activateAccount = (activation_token) =>
  API.post("/api/user/activate-user-account/", { activation_token });

// resend account activation email
export const resendActivation = (email) =>
  API.post("/api/user/resend-account-activation-link/", { email });

// sign in user
export const signIn = (loginData) => API.post("/api/user/login/", loginData);

// send password reset email
export const resetPassword = (email) =>
  API.post("/api/user/user-request-password-reset/", { email });

// set new password using reset link sent from above
export const setPassword = (newPasswords, password_token) =>
  API.post("/api/user/user-set-new-password/", {
    ...newPasswords,
    password_token,
  });

// user change password
export const changePassword = (passwords, userId) =>
  API.post(`/api/user/change-user-password/${userId}/`, passwords);

// emails
export const sendOutgoingEmail = (userId, body) =>
  API.post(`/api/appemail/outgoing-email/${userId}/`, body);

// POST REQUEST to create a new client
export const createSaasClient = (userId, body) =>
  API.post(`/api/azw-saas/admin/maintain-saas-clients/${userId}/`, body);

// POST REQUEST to add a client contact person
export const addClientContactPerson = (userId, body) =>
  API.post(
    `/api/azw-saas/admin/maintain-client-contact-person/${userId}/`,
    body
  );

// POST REQUEST API to create a new ledger group
export const createNewLedgerGroup = (userId, body) =>
  API.post(`/api/finance/maintain-ledger-groups/${userId}/`, body);

// POST REQUEST API to create a new ledger
export const createNewLedger = (userId, body) =>
  API.post(`/api/finance/maintain-ledgers/${userId}/`, body);

// POST REQUEST TO POST A TRANSACTION ENTRY
export const postNewTransaction = (userId, body) =>
  API.post(`/api/finance/accountant/maintain-transactions/${userId}/`, body);
