// import installed packages

// import styles
// import material ui items
// import shared/global items
// import components/pages
// import redux API

const SystemAdminLinks = ({ Link, pathname, toggleSubmenu, openSubMenu }) => {
  return (
    <>
      <Link
        to="/saas-clients/"
        className={
          `${pathname}` === "/saas-clients/" ? "nav__link active" : "nav__link"
        }
      >
        <i className="bx bxs-business"></i>
        <span>Saas Clients</span>
      </Link>
    </>
  );
};

export default SystemAdminLinks;
