import React from "react";
import moment from "moment";
import MaxDialog from "../../../components/common/MaxDialog";

const LicenseHistory = (props) => {
  const { openLicenseHistory, currentCompany } = props;
  const { setOpenLicenseHistory } = props;

  return (
    <MaxDialog isOpen={openLicenseHistory} maxHeight="80vh" maxWidth="1000px">
      <div class="dialog">
        <h3>{currentCompany?.company_name} licenses history</h3>
        {currentCompany?.licenses?.length > 0 ? (
          <table class="table__listing">
            <thead>
              <tr class="table__listingHeader">
                <th>Start Date</th>
                <th>Expiry Date</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {currentCompany?.licenses?.map((license) => (
                <tr class="table__listingItem" key={license?.id}>
                  <td>{moment(license?.start_date).format("LLL")}</td>
                  <td>{moment(license?.expiry_date).format("LLL")}</td>
                  <td>
                    {license?.is_expired ? (
                      <span className="red bd">Expired</span>
                    ) : (
                      <span className="dodgerblue bd">Current</span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <h4 class="not__available">No licenses available</h4>
        )}
        <div class="form__Buttons">
          <button type="button" onClick={() => setOpenLicenseHistory(false)}>
            Close
          </button>
        </div>
      </div>
    </MaxDialog>
  );
};

export default LicenseHistory;
