import * as actionTypes from "../actions/types";
import { updateObject } from "../utility";

const initialState = {
  clients: [],
};

const setSaasClients = (state, payload) => {
  return updateObject(state, {
    clients: payload,
  });
};

const createSaasClient = (state, payload) => {
  return updateObject(state, {
    clients: [...state?.clients, payload],
  });
};

const editSaasClient = (state, payload) => {
  return updateObject(state, {
    clients: state?.clients?.map((client) =>
      client?.id === payload?.id ? payload : client
    ),
  });
};

const addClientContactPerson = (state, payload) => {
  return updateObject(state, {
    clients: state?.clients?.map((client) =>
      client?.id === payload?.id ? payload : client
    ),
  });
};

const saasClientsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_SAAS_CLIENTS:
      return setSaasClients(state, payload);
    case actionTypes.NEW_SAAS_CLIENT:
      return createSaasClient(state, payload);
    case actionTypes.EDIT_SAAS_CLIENT:
      return editSaasClient(state, payload);
    case actionTypes.ADD_CLIENT_CONTACT:
      return addClientContactPerson(state, payload);
    default:
      return state;
  }
};

export default saasClientsReducer;
