// import installed packages
import { connect } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
// import styles

import "./Header.scss";
// import material ui items
// import shared/global items
import { showMobileNavMenu, showNavbar } from "../../../shared/scripts";
import azw_logo from "../../../images/azw_logo.png";
// import components/pages
import Login from "../../users/Login";
import Signup from "../../users/Signup";
import ForgotPassword from "../../users/ForgotPassword";
import ReactivateAccount from "../../users/ReactivateAccount";

// import redux API
import { OPEN_LOGIN } from "../../../redux/actions/types";
import { logout } from "../../../redux/actions/auth";

const Header = (props) => {
  const {
    loginForm,
    signupForm,
    resendActivationForm,
    forgotPasswordForm,
    username,
    loggedIn,
  } = props;
  const { logoutUser, openLogin } = props;
  const { pathname } = useLocation();

  const navigate = useNavigate();
  const session_cookie = localStorage.getItem("session_cookie");

  // console.log(location.pathname);

  return (
    <>
      <header className="header" id="header">
        <div className="header__left">
          {loggedIn && (
            <>
              <i
                className="bx bx-menu showBtn dodgerblue"
                id="menuBtnShow"
                onClick={showNavbar}
              ></i>
              <i
                className="bx bx-window-close hideBtn red"
                onClick={showNavbar}
                id="menuBtnHide"
              ></i>
            </>
          )}
          <h1>
            <img src={azw_logo} alt="" />
            <Link to="/">AZW Group</Link>
          </h1>
        </div>
        <div className="header__center mobile__menu">
          <i
            className="bx bx-menu"
            id="mobile__menuBtnShow"
            onClick={showMobileNavMenu}
          ></i>
          <i
            className="bx bx-window-close hideBtn red"
            onClick={showMobileNavMenu}
            id="mobile__menuBtnHide"
          ></i>
        </div>
        <div className="header__center desktop__menu" id="toggle__mobileMenu">
          <div className="dropdown">
            <button
              className={
                `${pathname}`?.includes(["/services/"])
                  ? "active__link dropbtn"
                  : "dropbtn"
              }
            >
              Services
            </button>
            <div className="dropdown-content">
              <Link to="/services/mobile-apps/">Mobile Apps</Link>
              <Link to="/services/web-apps/">Web Apps</Link>
            </div>
          </div>
          <div className="dropdown">
            <button
              className={
                `${pathname}`?.includes(["/products/"])
                  ? "active__link dropbtn"
                  : "dropbtn"
              }
            >
              Our Products
            </button>
            <div className="dropdown-content">
              <Link to="/products/mafunzo/">Mafunzo</Link>
              <Link to="/products/funzo-letu/">Funzo Letu</Link>
              <Link to="/products/courzehub/">CourZe Hub</Link>
              {/* <Link to="/products/gamefans/">Game Fans</Link> */}
            </div>
          </div>
          <div className="dropdown">
            <button
              className={
                `${pathname}`?.includes(["/about/"])
                  ? "active__link dropbtn"
                  : "dropbtn"
              }
            >
              About
            </button>
            <div className="dropdown-content">
              <Link to="/about/about-us/">About Us</Link>
            </div>
          </div>
          <div className="dropdown">
            <button
              className={
                `${pathname}`?.includes(["/contact/"])
                  ? "active__link dropbtn"
                  : "dropbtn"
              }
            >
              Contact Us
            </button>
            <div className="dropdown-content">
              <Link to="/contact/contact-us/">Contact Us</Link>
            </div>
          </div>
          {session_cookie ? (
            <div className="dropdown">
              <div className="dropbtn authenticated">
                <p>
                  <span className="green bd">&bull;</span> {username}
                </p>
                <i className="bx bx-caret-down"></i>
              </div>
              <div className="dropdown-content">
                <Link to="#" onClick={() => logoutUser(navigate)}>
                  Logout
                </Link>
              </div>
            </div>
          ) : (
            <button className="login__button" onClick={openLogin}>
              Login
            </button>
          )}
        </div>
      </header>

      {/* components */}
      {loginForm && <Login />}
      {signupForm && <Signup />}
      {forgotPasswordForm && <ForgotPassword />}
      {resendActivationForm && <ReactivateAccount />}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loginForm: state?.auth?.loginForm,
    signupForm: state?.auth?.signupForm,
    forgotPasswordForm: state?.auth?.forgotPasswordForm,
    resendActivationForm: state?.auth?.resendActivationForm,
    username: state?.auth?.user?.username,
    loggedIn: state.auth?.loggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: (navigate) => dispatch(logout(navigate)),
    openLogin: () => dispatch({ type: OPEN_LOGIN }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
