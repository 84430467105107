import React from "react";
import { connect } from "react-redux";
import MinDialog from "../../../../components/common/MinDialog";
import { edit_ledger } from "../../../../redux/actions/finance";
import { START_LOADING } from "../../../../redux/actions/types";

import CircularProgress from "@mui/material/CircularProgress";

const EditLedger = (props) => {
  const { currentLedgerItem, openEditLedger, loading, userId, ledger_groups } =
    props;
  const { setCurrentLedgerItem, setOpenEditLedger, startLoading, editLedger } =
    props;

  const { name, ledger_group } = currentLedgerItem;

  const handleChange = (e) => {
    setCurrentLedgerItem({
      ...currentLedgerItem,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (name.trim() === "" || ledger_group?.trim() === "") {
      return window.alert("Fields cannot be empty");
    }
    startLoading();
    const body = {
      ...currentLedgerItem,
    };
    editLedger(userId, body);
  };

  return (
    <MinDialog isOpen={openEditLedger}>
      <div class="dialog" id={loading ? "formSubmitting" : ""}>
        <h3>Edit ledger details</h3>
        <div class="dialog__rowSingleItem">
          <label for="">Ledger Group</label>
          <select
            name="ledger_group"
            onChange={handleChange}
            value={ledger_group}
          >
            <option value="" disabled>
              --select--
            </option>
            {ledger_groups?.map((ledger_group) => (
              <option value={ledger_group?.id} key={ledger_group?.id}>
                {ledger_group?.name}
              </option>
            ))}
          </select>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div class="dialog__rowSingleItem">
          <label for="">Ledger Name</label>
          <input type="text" name="name" value={name} onChange={handleChange} />
        </div>
        <div class="form__Buttons">
          <button type="button" onClick={() => setOpenEditLedger(false)}>
            Close
          </button>
          <button type="button" onClick={handleSubmit}>
            Update
          </button>
        </div>
      </div>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
    ledger_groups: state.finance?.ledger_groups,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    editLedger: (userId, body) => dispatch(edit_ledger(userId, body)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditLedger);
