import { combineReducers } from "redux";

import auth from "./auth";
import shared from "./shared";
import emails from "./emails";
import saas_clients from "./saas_clients";
import finance from "./finance";

export default combineReducers({
  auth,
  shared,
  emails,
  saas_clients,
  finance,
});
