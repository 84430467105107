import React, { useEffect, useState } from "react";
import moment from "moment";
import { connect } from "react-redux";
import {
  START_LOADING,
  STOP_LOADING,
} from "../../../../../redux/actions/types";
import { showError } from "../../../../../redux/actions/shared";
import API from "../../../../../shared/axios";
import CircularProgress from "@mui/material/CircularProgress";
import MediumDialog from "../../../../../components/common/MediumDialog";
import GenerateClientLicense from "./GenerateClientLicense";

const ClientLicenses = (props) => {
  const { currentClient, userId, loading, openClientLicenses } = props;
  const { startLoading, stopLoading, setOpenClientLicenses } = props;

  const [licenses, setLicenses] = useState([]);

  const [openGenerateLicense, setOpenGenerateLicense] = useState(false);

  // useEffect to get licenses
  useEffect(() => {
    if (userId) {
      const fetchLicenses = async () => {
        startLoading();
        await API.get(
          `/api/azw-saas/admin/maintain-client-licenses/${userId}/${currentClient?.id}/`
        ).then((res) => {
          setLicenses(res?.data?.client_licenses_data);
        });
      };
      fetchLicenses()
        .catch((err) => showError(err))
        .finally(() => stopLoading());
    }
  }, [startLoading, stopLoading, userId, currentClient?.id]);

  const handleActivateOrDeactivateLicense = async (actionType, licenseId) => {
    if (
      window.confirm(`Are you sure you want to ${actionType} this license?`)
    ) {
      startLoading();
      await API.post(
        `/api/azw-saas/admin/activate-or-deactivate-license/${userId}/${licenseId}/${actionType}/`
      )
        .then((res) => {
          setLicenses(
            licenses?.map((license) =>
              license?.id === res?.data?.updated_license?.id
                ? res?.data?.updated_license
                : license
            )
          );
          window.alert(`License ${actionType}d successfully`);
        })
        .catch((err) => showError(err))
        .finally(() => stopLoading());
    }
  };

  return (
    <>
      <MediumDialog isOpen={openClientLicenses}>
        <div className="dialog" id={loading ? "formSubmitting" : ""}>
          <h3>{currentClient?.company_name} licenses</h3>
          {loading && (
            <CircularProgress
              style={{ position: "absolute", marginLeft: "45%" }}
            />
          )}
          {licenses?.length > 0 ? (
            <table class="table__listing">
              <thead>
                <tr class="table__listingHeader">
                  <th>Start Date</th>
                  <th>Expiry Date</th>
                  <th>License Type</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {licenses?.map((item) => (
                  <tr class="table__listingItem" key={item?.id}>
                    <td>{moment(item?.start_date).format("LLL")}</td>
                    <td>{moment(item?.expiry_date).format("LLL")}</td>
                    <td>
                      {item?.is_trial ? (
                        <span>Trial License</span>
                      ) : (
                        <span>Full License</span>
                      )}
                    </td>
                    <td>
                      {item?.is_expired ? (
                        <span className="red bd">Expired</span>
                      ) : (
                        <>
                          {item?.is_active && (
                            <span
                              onClick={() =>
                                handleActivateOrDeactivateLicense(
                                  "deactivate",
                                  item?.id
                                )
                              }
                              className="button__sp red bd"
                            >
                              Deactivate
                            </span>
                          )}{" "}
                          {!item?.is_active && (
                            <span
                              onClick={() =>
                                handleActivateOrDeactivateLicense(
                                  "activate",
                                  item?.id
                                )
                              }
                              className="button__sp green bd"
                            >
                              Activate
                            </span>
                          )}
                        </>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <h4 class="not__available">No licenses for this client</h4>
          )}
          <div class="form__Buttons">
            <button type="button" onClick={() => setOpenClientLicenses(false)}>
              Close
            </button>
            <button type="button" onClick={() => setOpenGenerateLicense(true)}>
              Generate License
            </button>
          </div>
        </div>
      </MediumDialog>
      {openGenerateLicense && (
        <GenerateClientLicense
          openGenerateLicense={openGenerateLicense}
          setOpenGenerateLicense={setOpenGenerateLicense}
          currentClient={currentClient}
          setLicenses={setLicenses}
          licenses={licenses}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientLicenses);
