import * as actionTypes from "./types";
import * as api from "../../api";
import { showError, stopLoading } from "./shared";

export const get_my_sent_or_received_emails =
  (userId, sent_or_received) => async (dispatch) => {
    await api
      .getMySentOrReceivedEmails(userId, sent_or_received)
      .then((res) => {
        dispatch({
          type: actionTypes.SET_EMAILS,
          payload: { emails_data: res?.data?.emails_data, sent_or_received },
        });
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading(dispatch));
  };

export const send_outgoing_email = (userId, body) => async (dispatch) => {
  await api
    .sendOutgoingEmail(userId, body)
    .then((res) => {
      window.alert(res.data?.detail);
      dispatch({
        type: actionTypes.NEW_SENT_EMAIL,
        payload: {
          new_sent_email: res.data?.new_sent_email,
          toggle_compose_email: false,
        },
      });
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// function to update read emails
export const update_read_email = (userId, emailId) => async (dispatch) => {
  await api
    .updateReadEmail(userId, emailId)
    .then((res) => {
      dispatch({ type: actionTypes.UPDATE_READ_EMAIL, payload: emailId });
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// function to delete email
export const delete_email =
  (userId, emailId, email_type) => async (dispatch) => {
    await api
      .deleteEmail(userId, emailId, email_type)
      .then((res) => {
        dispatch({
          type: actionTypes.DELETE_EMAIL,
          payload: { emailId, email_type },
        });
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading(dispatch));
  };
