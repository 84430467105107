import React from "react";
import MaxDialog from "../../../../components/common/MaxDialog";
import { formatWithCommas } from "../../../../shared/sharedFunctions";

const TransactionItems = (props) => {
  const { openTransactionItems, currentBaseTransaction } = props;
  const { setOpenTransactionItems } = props;

  return (
    <MaxDialog isOpen={openTransactionItems} maxHeight="85vh" maxWidth="1200px">
      <div class="dialog">
        <h3>Transaction Items Details</h3>
        <table class="table__listing">
          <thead>
            <tr class="table__listingHeader">
              <th>Ledger</th>
              <th>Debit</th>
              <th>Credit</th>
            </tr>
          </thead>
          <tbody>
            {currentBaseTransaction?.transaction_items?.map(
              (transaction, index) => (
                <tr class="table__listingItem" key={transaction?.id}>
                  <td>{transaction?.ledger}</td>
                  <td>
                    {transaction?.entry_type === "Debit" && (
                      <span>{formatWithCommas(transaction?.amount)}</span>
                    )}
                  </td>
                  <td>
                    {transaction?.entry_type === "Credit" && (
                      <span>{formatWithCommas(transaction?.amount)}</span>
                    )}
                  </td>
                </tr>
              )
            )}
            <tr className="table__listingFooter">
              <th>Totals</th>
              <th>{formatWithCommas(currentBaseTransaction?.total_debits)}</th>
              <th>{formatWithCommas(currentBaseTransaction?.total_credits)}</th>
            </tr>
          </tbody>
        </table>
        <div class="form__Buttons">
          <button type="button" onClick={() => setOpenTransactionItems(false)}>
            Close
          </button>
        </div>
      </div>
    </MaxDialog>
  );
};

export default TransactionItems;
