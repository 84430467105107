import API from "../shared/axios";

export const deleteEmail = (userId, emailId, email_type) =>
  API.delete(`/api/appemail/delete-email/${userId}/${emailId}/${email_type}/`);

// DELETE REQUEST TO delete a ledger or ledger group
export const deleteLedgerOrGroup = (userId, itemId, ledgerOrGroup) =>
  API.delete(
    `/api/finance/delete-ledger-or-group/${userId}/${itemId}/${ledgerOrGroup}/`
  );
