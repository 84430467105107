// import installed packages
import React, { useEffect } from "react";
// import styles
import "./Courzehub.scss";
// import material ui items
// import shared/global items
import courzehub from "../../../images/courzehub.jpg";
// import components/pages
// import redux API

const Courzehub = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="courzehub">
      <div className="first__row">
        <div>
          <h2>
            Facilitating skills transfer from industry experts to upcoming
            professionals.
          </h2>
          <br />
          <a
            href="https://courzehub.azwgroup.com/"
            className="explore__more add__button"
            target="__blank"
          >
            Explore More
          </a>
        </div>
        <img src={courzehub} alt="" />
      </div>
      <div className="second__row">
        <h2>CourZe Hub Features</h2>
        <h3>Learn from the best industry experts</h3>
        <ul>
          <li>
            A great opportunity to learn from the experience of the best
            industry experts across multiple industries.
          </li>
        </ul>
        <h3>Connect with like-minded people</h3>
        <ul>
          <li>
            A chance to meet people who think like you and explore ideas
            together.
          </li>
        </ul>
        <h3>Learn at your own pace</h3>
        <ul>
          <li>
            No rush, get the skills when you require them, they are always here.
          </li>
        </ul>
        <h3>Become a contributor of great solutions to African problems</h3>
        <ul>
          <li>
            Don't just watch solutions coming up, engage with great thinkers and
            develop solutions for this great African continent.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Courzehub;
