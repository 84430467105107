import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { showError } from "../../../redux/actions/shared";
import { START_LOADING, STOP_LOADING } from "../../../redux/actions/types";
import API from "../../../shared/axios";
import RenewLicense from "./components/RenewLicense";
import LicenseHistory from "./LicenseHistory";
import CircularProgress from "@mui/material/CircularProgress";

const MyLicenses = (props) => {
  const { userId, loading } = props;
  const { startLoading, stopLoading } = props;

  const [myCompanies, setMyCompanies] = useState([]);
  const [openLicenseHistory, setOpenLicenseHistory] = useState(false);
  const [currentCompany, setCurrentCompany] = useState({});
  const [openRenewLicense, setOpenRenewLicense] = useState(false);

  useEffect(() => {
    if (userId) {
      startLoading();
      const fetchLicenses = async () => {
        await API.get(
          `/api/azw-saas/client/maintain-my-companies-licenses/${userId}/`
        ).then((res) => {
          setMyCompanies(res?.data?.my_companies_data);
        });
      };
      fetchLicenses()
        .catch((err) => showError(err))
        .finally(() => stopLoading());
    }
  }, [startLoading, stopLoading, userId]);

  const handleOpenLicenseHistory = (company) => {
    setCurrentCompany(company);
    setOpenLicenseHistory(true);
  };

  const handleOpenRenewLicense = (company) => {
    setCurrentCompany(company);
    setOpenRenewLicense(true);
  };

  return (
    <>
      <div className="table__parent" id={loading ? "pageSubmitting" : ""}>
        <div class="table__parentHeader">
          <h3>Maintain your licenses here</h3>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        {myCompanies?.length > 0 ? (
          <table class="table__listing">
            <thead>
              <tr class="table__listingHeader">
                <th>Company Name</th>
                <th>Status</th>
                <th>License Status</th>
                <th>Licenses History</th>
              </tr>
            </thead>
            <tbody>
              {myCompanies?.map((company) => (
                <tr class="table__listingItem" key={company?.id}>
                  <td>{company?.company_name}</td>
                  <td>
                    {company?.is_active ? (
                      <span>Active</span>
                    ) : (
                      <span>Inactive</span>
                    )}
                  </td>
                  <td>
                    {/* if active licenses exist */}
                    {company?.valid_licenses > 0 && (
                      <span className="dodgerblue bd">Active</span>
                    )}
                    {/* if payment is pending for license */}
                    {company?.pending_invoice_payment && (
                      <a
                        href={`/finance/make-payment/${company?.payable_amount}/${company?.merchant_ref}/`}
                        className="green bd button__sp"
                      >
                        Make Payment
                      </a>
                    )}
                    {/* if payment confirmation is pending for license */}
                    {company?.pending_payment_confirmation && (
                      <a
                        href={`/pesapal/transaction/completed/?pesapal_merchant_reference=${company?.pesapal_merchant_reference}&pesapal_transaction_tracking_id=${company?.pesapal_transaction_tracking_id}`}
                        className="green bd button__sp"
                      >
                        Confirm Payment
                      </a>
                    )}
                    {/*  if none of the conditions above is met, then we show the option of renew license */}
                    {company?.valid_licenses === 0 &&
                      !company?.pending_invoice_payment &&
                      !company?.pending_payment_confirmation && (
                        <span
                          className="green bd button__sp"
                          onClick={() => handleOpenRenewLicense(company)}
                        >
                          Renew License
                        </span>
                      )}
                  </td>
                  <td
                    className="dodgerblue button__sp bd"
                    onClick={() => handleOpenLicenseHistory(company)}
                  >
                    View
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <h4 class="not__available">No available licenses</h4>
        )}
      </div>
      {/* child components */}
      {openLicenseHistory && (
        <LicenseHistory
          openLicenseHistory={openLicenseHistory}
          setOpenLicenseHistory={setOpenLicenseHistory}
          currentCompany={currentCompany}
        />
      )}
      {/* renew license component not the page itself */}
      {openRenewLicense && (
        <RenewLicense
          openRenewLicense={openRenewLicense}
          setOpenRenewLicense={setOpenRenewLicense}
          currentCompany={currentCompany}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyLicenses);
