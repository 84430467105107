// import installed packages
import { useEffect } from "react";
// import styles
import "./Home.scss";
// import material ui items
// import shared/global items

// import components/pages
import Featured from "./components/Featured/Featured";
import MoreInfo from "./components/MoreInfo/MoreInfo";
import Overview from "./components/Overview/Overview";
import Products from "./components/Products/Products";

// import redux API

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="home">
      <Overview />
      <Featured />
      <Products />
      <MoreInfo />
    </div>
  );
};

export default Home;
