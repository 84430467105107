import React from "react";

import "./Mafunzo.scss";

import online_tutoring from "../../../images/online_tutoring.jpg";

const Mafunzo = () => {
  return (
    // we use funzo to enable reuse in both funzoletu and mafunzo
    <div className="funzo">
      <div className="banner">
        <div className="banner__left">
          <h2>
            <span className="dodgerblue">Mafunzo: </span>Connect with like
            minded students to discuss great ideas and topics
          </h2>
          <p>The people we interact with shape our future</p>
          <a href="https://mafunzo.azwgroup.com/" target="__blank">
            Explore More
          </a>
        </div>
        <div className="banner__right">
          <img src={online_tutoring} alt="" />
        </div>
      </div>
      <div className="benefits">
        <h3>Mafunzo Benefits:</h3>
        <ul>
          <li>Connect with like-minded students to discuss great ideas</li>
          <li>
            Search for ctudent connections using weak and strong subjects/topics
          </li>
          <li>Give and receive knowledge and wisdom</li>
          <li>Share you dreams and passions with other students</li>
        </ul>
      </div>
    </div>
  );
};

export default Mafunzo;
