import API from "../shared/axios";

// get user data
export const getUser = () => API.get("/api/user/get-user-data/");

// emails actions
export const getMySentOrReceivedEmails = (userId, sent_or_received) =>
  API.get(`/api/appemail/my-emails/${userId}/${sent_or_received}/`);

// GET REQUEST to get saas clients
export const getSaasClients = (userId) =>
  API.get(`/api/azw-saas/admin/maintain-saas-clients/${userId}/`);

// GET REQUEST to get parent ledger groups, ledger groups and ledgers
export const getLedgers = (userId) =>
  API.get(`/api/finance/maintain-ledgers/${userId}/`);

// API to get report types
export const getReportTypes = (userId) =>
  API.get(`/api/finance/accountant/maintain-report-types/${userId}/`);
