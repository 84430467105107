// toggle sidebar on menu button click
export const showNavbar = () => {
  // const toggle = document.getElementById("header-toggle")
  const nav = document.getElementById("nav-bar"),
    bodypd = document.getElementById("body-pd");
  // headerpd = document.getElementById("header");

  // show navbar
  nav.classList.toggle("show");
  const menuBtnShow = document.getElementById("menuBtnShow");
  const menuBtnHide = document.getElementById("menuBtnHide");

  menuBtnShow.classList.toggle("hideBtn");
  menuBtnHide.classList.toggle("hideBtn");

  // change icon
  // toggle.classList.toggle("bx-x");

  // add padding to body
  bodypd.classList.toggle("body-pd");

  // add padding to header
  //   headerpd.classList.toggle("body-pd");
};

// show nav menu on mobile
export const showMobileNavMenu = () => {
  const mobileMenu = document.getElementById("toggle__mobileMenu");
  mobileMenu.classList.toggle("toggle__mobileMenu");
  const menuBtnShow = document.getElementById("mobile__menuBtnShow");
  const menuBtnHide = document.getElementById("mobile__menuBtnHide");
  menuBtnShow.classList.toggle("hideBtn");
  menuBtnHide.classList.toggle("hideBtn");
};

// import installed packages
// import styles
// import material ui items
// import shared/global items
// import components/pages
// import redux API
