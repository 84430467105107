import React, { useState } from "react";
import { connect } from "react-redux";
import MinDialog from "../../../../../components/common/MinDialog";
import { START_LOADING } from "../../../../../redux/actions/types";
import CircularProgress from "@mui/material/CircularProgress";
import {
  ifEmpty,
  resetFormValues,
} from "../../../../../shared/sharedFunctions";
import { create_saas_client } from "../../../../../redux/actions/saas_clients";

const AddClient = (props) => {
  const { openAddClient, userId, loading } = props;
  const { setOpenAddClient, startLoading, createSaasClient } = props;

  const [clientDetails, setClientDetails] = useState({
    company_name: "",
    client_type: "",
    access_url: "",
    monthly_rate: "",
  });
  const { company_name, client_type, access_url, monthly_rate } = clientDetails;

  const handleChange = (e) => {
    setClientDetails({ ...clientDetails, [e.target.name]: e.target.value });
  };

  const resetForm = () => {
    resetFormValues(clientDetails);
  };

  const handleClose = () => {
    setOpenAddClient(false);
    resetForm();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (ifEmpty(clientDetails)) {
      return window.alert("All fields are required");
    }
    startLoading();
    createSaasClient(userId, clientDetails, resetForm);
  };

  return (
    <MinDialog isOpen={openAddClient}>
      <div class="dialog" id={loading ? "formSubmitting" : ""}>
        <h3>New client details</h3>
        <div class="dialog__rowSingleItem">
          <label for="">Name</label>
          <input
            type="text"
            name="company_name"
            onChange={handleChange}
            value={company_name}
          />
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div class="dialog__rowSingleItem">
          <label for="">Client Type</label>
          <select
            name="client_type"
            onChange={handleChange}
            value={client_type}
          >
            <option value="" disabled selected>
              --select--
            </option>
            <option value="school">School</option>
          </select>
        </div>
        <div class="dialog__rowSingleItem">
          <label for="">Access Url</label>
          <input
            type="url"
            name="access_url"
            onChange={handleChange}
            value={access_url}
          />
        </div>
        <div class="dialog__rowSingleItem">
          <label for="">Monthly Rate</label>
          <input
            type="number"
            name="monthly_rate"
            onChange={handleChange}
            value={monthly_rate}
            min="1"
          />
        </div>
        <div class="form__Buttons">
          <button type="button" onClick={handleClose}>
            Close
          </button>
          <button type="button" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    createSaasClient: (userId, body, resetForm) =>
      dispatch(create_saas_client(userId, body, resetForm)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddClient);
