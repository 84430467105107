import React, { useState, useEffect } from "react";
import moment from "moment";
import { connect } from "react-redux";
import MinDialog from "../../../../components/common/MinDialog";
import { START_LOADING, STOP_LOADING } from "../../../../redux/actions/types";
import {
  resetFormValues,
  formatWithCommas,
  ifEmpty,
} from "../../../../shared/sharedFunctions";
import CircularProgress from "@mui/material/CircularProgress";
import API from "../../../../shared/axios";
import { showError } from "../../../../redux/actions/shared";

const RenewLicense = (props) => {
  const { userId, loading, currentCompany, openRenewLicense } = props;
  const { startLoading, setOpenRenewLicense, stopLoading } = props;

  const [licenseDetails, setLicenseDetails] = useState({
    license_duration: "",
  });

  // to avoid endless loops when license duration changes, we set state of total cost outside the licenseDetails
  const [totalCost, setTotalCost] = useState("");

  const { license_duration } = licenseDetails;

  // useEffect to get the total cost based on selected item
  useEffect(() => {
    setTotalCost(
      Math.ceil(
        (Number(currentCompany?.monthly_rate) / 30) * Number(license_duration)
      )
    );
  }, [currentCompany?.monthly_rate, license_duration]);

  const resetForm = () => {
    resetFormValues();
  };

  const handleClose = () => {
    resetForm();
    setOpenRenewLicense(false);
  };

  const handleChange = (e) => {
    setLicenseDetails({ ...licenseDetails, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (ifEmpty(licenseDetails)) {
      return window.alert("Select the license duration");
    }
    startLoading();
    const body = {
      ...licenseDetails,
      totalCost,
      start_date: new Date(),
      companyId: currentCompany?.id,
    };
    await API.post(
      `/api/azw-saas/client/maintain-my-companies-licenses/${userId}/`,
      body
    )
      .then((res) => {
        window.alert(res?.data?.detail);
        // redirect to the payment page
        window.location.replace(
          `/finance/make-payment/${res?.data?.payable_amount}/${res?.data?.merchant_ref}/`
        );
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  return (
    <MinDialog isOpen={openRenewLicense}>
      <form class="dialog" id={loading ? "formSubmitting" : ""}>
        <h3>Renew your license here</h3>
        <div class="dialog__rowSingleItem">
          <label for="">Monthly Rate (Kshs)</label>
          <input
            type="number"
            value={formatWithCommas(currentCompany?.monthly_rate)}
            disabled
          />
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div class="dialog__rowSingleItem">
          <label for="">License Duration</label>
          <select
            name="license_duration"
            onChange={handleChange}
            value={license_duration}
          >
            <option value="" disabled selected>
              --select--
            </option>
            <option value="30">1 Month</option>
            <option value="60">2 Months</option>
            <option value="90">3 Months</option>
          </select>
        </div>
        <div class="dialog__rowSingleItem">
          <label for="">Total Cost</label>
          <input type="number" value={Math.ceil(totalCost)} disabled />
        </div>
        <div class="dialog__rowSingleItem">
          <label for="">Effective Start Date After Payment Confirmation</label>
          <input
            type="text"
            value={moment(new Date()).format("LLL")}
            disabled
          />
        </div>
        <div class="form__Buttons">
          <button type="button" onClick={handleClose}>
            Close
          </button>
          <button type="submit" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </form>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RenewLicense);
