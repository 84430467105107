// import installed packages
// import styles
import "./Overview.scss";
// import material ui items
// import shared/global items
import sample_image from "../../../../images/sample_image.png";
// import components/pages
// import redux API

import React from "react";

const Overview = () => {
  return (
    <div className="overview">
      <div className="overview__left">
        <h3>Software Consulting &amp; Development</h3>
        <h2>Software to bring the best in you.</h2>
        <p>
          We endeavour to build software to ease your work so you can focus on
          building your vision.
        </p>
        {/* <button type="button">Discover More</button> */}
      </div>
      <div className="overview__right">
        <img src={sample_image} alt="" />
      </div>
    </div>
  );
};

export default Overview;
