// import installed packages
import React, { useEffect } from "react";
// import styles
import "./WebApps.scss";
// import material ui items
// import shared/global items
import digitize from "../../../images/digitize.jpg";
// import components/pages
// import redux API

const WebApps = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="web__apps">
      <div className="first__row">
        <img src={digitize} alt="" />
        <h2>Digitize your service to reach more customers efficiently.</h2>
      </div>
      <div className="second__row">
        <h2>What we offer you.</h2>
        <h3>Beautiful user interfaces.</h3>
        <ul>
          <li>Appeal to your customers with the first impression.</li>
        </ul>
        <h3>Stable web app performance</h3>
        <ul>
          <li>Experience reliable performance from your web app service.</li>
        </ul>
        <h3>Expandable functionality</h3>
        <ul>
          <li>
            Enjoy additional features as your customer base and needs changes
          </li>
        </ul>
      </div>
    </div>
  );
};

export default WebApps;
