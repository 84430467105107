// import installed packages
import React from "react";
import { connect } from "react-redux";
import moment from "moment";
// import styles
import "./EmailsListing.scss";
// import material ui items
import CircularProgress from "@mui/material/CircularProgress";
// import shared/global items
// import components/pages
import EmailDetails from "./EmailDetails";
// import redux API
import { START_LOADING, TOGGLE_EMAIL } from "../../../redux/actions/types";
import { delete_email, update_read_email } from "../../../redux/actions/emails";

const EmailsListing = (props) => {
  const { emails, userId, activeEmailComponent, loading } = props;
  const { openEmailDetails, updateReadEmail, deleteEmail, startLoading } =
    props;

  const openReceivedEmail = (email) => {
    openEmailDetails(email);
    if (activeEmailComponent === "inbox") {
      updateReadEmail(userId, email?.id);
    }
  };

  const deleteEmailItem = (email) => {
    if (window.confirm("Click ok to confirm delete email")) {
      startLoading();
      deleteEmail(userId, email?.id, activeEmailComponent);
    }
  };

  return (
    <>
      <ul className="emails__listing" id={loading ? "pageSubmitting" : ""}>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "40%" }}
          />
        )}
        {emails?.map((email) => (
          <li
            className={
              activeEmailComponent === "inbox" && !email?.is_read && "not__read"
            }
          >
            <div className="checkbox">
              <input type="checkbox" name="" id="" />
            </div>
            <div
              onClick={() => openReceivedEmail(email)}
              className="email__overview"
            >
              <div className="sender">
                {activeEmailComponent === "inbox"
                  ? email?.sender_name
                  : `To: ${email?.email_to}`}
                {}
              </div>
              <div className="subject">{email?.subject}</div>
              <div className="time">
                {activeEmailComponent === "inbox"
                  ? moment(email?.time_received).format("lll")
                  : moment(email?.time_sent).format("lll")}
              </div>
            </div>
            <div className="actions">
              <i
                className="fa fa-trash-o button"
                aria-hidden="true"
                onClick={() => deleteEmailItem(email)}
              ></i>
            </div>
          </li>
        ))}
      </ul>
      <EmailDetails />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state.auth?.user?.id,
    loading: state?.shared?.loading,
    activeEmailComponent: state?.emails?.activeEmailComponent,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openEmailDetails: (email) =>
      dispatch({ type: TOGGLE_EMAIL, payload: { openEmail: true, email } }),
    updateReadEmail: (userId, emailId) =>
      dispatch(update_read_email(userId, emailId)),
    deleteEmail: (userId, emailId, email_type) =>
      dispatch(delete_email(userId, emailId, email_type)),
    startLoading: () => dispatch({ type: START_LOADING }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailsListing);
