// import installed packages
import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";

// import styles
// import material ui items
import CircularProgress from "@mui/material/CircularProgress";
// import shared/global items
import { ifEmpty } from "../../../shared/sharedFunctions";
// import components/pages
import MaxDialog from "../../../components/common/MaxDialog";
import Markdown from "../../../components/common/Markdown/Markdown";
// import redux API
import {
  START_LOADING,
  TOGGLE_COMPOSE_EMAIL,
} from "../../../redux/actions/types";
import { send_outgoing_email } from "../../../redux/actions/emails";

const ComposeEmail = (props) => {
  const { openComposeEmailStatus, currentEmail, userId, loading } = props;
  const { closeComposeEmail, startLoading } = props;
  const dispatch = useDispatch();

  const [newEmail, setNewEmail] = useState({
    email_to: "",
    subject: "",
  });
  const [message, setMessage] = useState("");

  // destructure
  const { email_to, subject } = newEmail;

  const handleChange = (e) => {
    setNewEmail({ ...newEmail, [e.target.name]: e.target.value });
  };

  const sendReplyEmail = (e) => {
    e.preventDefault();
    if (message?.trim() === "") {
      return window.alert("Message field cannot be empty");
    }
    if (openComposeEmailStatus?.new_or_reply === "new" && ifEmpty(newEmail)) {
      return window.alert("All fields must be filled");
    }
    startLoading();
    let body = new FormData();
    if (openComposeEmailStatus?.new_or_reply === "new") {
      body.append("to_emails", email_to);
      body.append("subject", subject);
    } else if (openComposeEmailStatus?.new_or_reply === "reply") {
      body.append("to_emails", currentEmail?.sender_email);
      body.append("subject", currentEmail?.subject);
    }
    body.append("html_content", message);

    dispatch(send_outgoing_email(userId, body));
  };
  return (
    <MaxDialog
      isOpen={openComposeEmailStatus?.isOpen}
      maxWidth="1200px"
      maxHeight="80vh"
    >
      <form className="dialog" id={loading ? "formSubmitting" : ""}>
        {openComposeEmailStatus?.new_or_reply === "new" ? (
          <>
            <h3>Compose a new email.</h3>
            <div className="dialog__rowSingleItem">
              <label htmlFor="">Email To</label>
              <input
                type="text"
                name="email_to"
                onChange={handleChange}
                value={email_to}
              />
            </div>
            <div className="dialog__rowSingleItem">
              <label htmlFor="">Subject</label>
              <input
                type="text"
                name="subject"
                onChange={handleChange}
                value={subject}
              />
            </div>
          </>
        ) : (
          <>
            <h3>Replying to {currentEmail?.sender_email}</h3>
            <h4>Subject: {currentEmail?.subject}</h4>
            <p>Reply to: {currentEmail?.sender_email}</p>
          </>
        )}
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div className="dialog__rowSingleItem">
          <label>Email Message</label>
        </div>
        <Markdown content={message} setContent={setMessage} />

        <div className="form__Buttons">
          <button type="button" onClick={closeComposeEmail}>
            Close
          </button>
          <button type="submit" onClick={sendReplyEmail}>
            Send
          </button>
        </div>
      </form>
    </MaxDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    openComposeEmailStatus: state?.emails?.openComposeEmailStatus,
    currentEmail: state?.emails?.currentEmail,
    userId: state.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeComposeEmail: () =>
      dispatch({ type: TOGGLE_COMPOSE_EMAIL, payload: false }),
    startLoading: () => dispatch({ type: START_LOADING }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ComposeEmail);
