// import installed packages
import { useEffect, useState } from "react";
import { connect } from "react-redux";
// import styles
import "./Reports.scss";
// import material ui items
import CircularProgress from "@mui/material/CircularProgress";
// import shared/global items
import globals from "../../../shared/globals";
import API from "../../../shared/axios";
import { ifEmpty } from "../../../shared/sharedFunctions";
// import components/pages

// import redux API
import { get_report_types } from "../../../redux/actions/finance";
import { START_LOADING, STOP_LOADING } from "../../../redux/actions/types";

import TrialBalanceTemplate from "./components/TrialBalanceTemplate/TrialBalanceTemplate";
import ProfitLoss from "./components/ProfitLoss/ProfitLoss";
import BalanceSheet from "./components/BalanceSheet/BalanceSheet";
import LedgerReport from "./components/LedgerReport/LedgerReport";
import { showError } from "../../../redux/actions/shared";

const Reports = (props) => {
  const { loading, userId, reportTypes, specificReports } = props; // get state from props
  const { startLoading, getReportTypes, stopLoading } = props; // get dispatch actions from props

  const { companyName } = globals;

  const [openReportTemplate, setOpenReportTemplate] = useState(false);
  const [reportData, setReportData] = useState({});

  const [currentReport, setCurrentReport] = useState({
    currentReportType: "", // report type
    currentSpecificReport: "", // specific report
    start_date: "",
    end_date: "",
  });

  // destructure

  const { currentReportType, currentSpecificReport, start_date, end_date } =
    currentReport;

  // useEffect to get report types listing
  useEffect(() => {
    if (userId && reportTypes?.length === 0) {
      startLoading();
      getReportTypes(userId);
    }
  }, [userId, reportTypes?.length, startLoading, getReportTypes]);

  const handleChange = (e) => {
    setCurrentReport({ ...currentReport, [e.target.name]: e.target.value });
  };

  // function to submit generate report request
  const handleSubmit = (e) => {
    e.preventDefault();
    if (currentSpecificReport !== "Balance Sheet" && ifEmpty(currentReport)) {
      return alert("Please fill in all fields");
    } else if (currentSpecificReport === "Balance Sheet" && end_date === "") {
      return alert("Please fill in all fields");
    }
    if (end_date < start_date) {
      return alert("End date must be greater than start date.");
    }
    startLoading();
    let body;
    let url;

    body = { ...currentReport };
    url = `/api/finance/accountant/maintain-specific-report/${userId}/`;

    const fetchReport = async () => {
      const res = await API.post(url, body);
      setReportData(res.data?.report_data);
      // open the report template here
      setOpenReportTemplate(true);
    };
    fetchReport()
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  return (
    <>
      <div className="reports dialog" id={loading ? "pageSubmitting" : ""}>
        <h3>Reports Center</h3>
        <h4>Fill sections below to generate report</h4>
        <div className="dialog__row">
          <span>
            <label htmlFor="">Report type</label>
            <select
              name="currentReportType"
              onChange={handleChange}
              value={currentReportType}
            >
              <option value="" disabled selected>
                Choose report type
              </option>
              {reportTypes?.map((item) => (
                <option value={item?.name} key={item?.name}>
                  {item?.name}
                </option>
              ))}
            </select>
          </span>
          <span>
            <label htmlFor="">Report</label>
            <select
              name="currentSpecificReport"
              onChange={handleChange}
              value={currentSpecificReport}
            >
              <option value="">Choose report</option>
              {/* we filter specific reports based on report type */}
              {specificReports
                ?.filter((item) => item.reportType === currentReportType)
                .map((item) => (
                  <option value={item?.name}>{item?.name}</option>
                ))}
            </select>
          </span>
          {/* don't show start date if report is balance sheet or student balances*/}
          {currentSpecificReport !== "Balance Sheet" && (
            <span>
              <label htmlFor="">Start Date</label>
              <input
                type="date"
                name="start_date"
                onChange={handleChange}
                value={start_date}
              />
            </span>
          )}

          <span>
            <label htmlFor="">End Date</label>
            <input
              type="date"
              name="end_date"
              onChange={handleChange}
              value={end_date}
            />
          </span>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "2%" }}
          />
        )}
        <button type="button" className="add__button" onClick={handleSubmit}>
          View Report
        </button>
      </div>

      {/* child components */}
      {/* trial balance */}

      {openReportTemplate && (
        <>
          {currentSpecificReport === "Trial Balance" && (
            <TrialBalanceTemplate
              openReportTemplate={openReportTemplate}
              setOpenReportTemplate={setOpenReportTemplate}
              reportData={reportData}
              start_date={start_date}
              end_date={end_date}
              companyName={companyName}
            />
          )}
          {/* profit and loss */}
          {currentSpecificReport === "Profit & Loss" && (
            <ProfitLoss
              openReportTemplate={openReportTemplate}
              setOpenReportTemplate={setOpenReportTemplate}
              reportData={reportData}
              start_date={start_date}
              end_date={end_date}
              companyName={companyName}
            />
          )}
          {/* balance sheet */}
          {currentSpecificReport === "Balance Sheet" && (
            <BalanceSheet
              openReportTemplate={openReportTemplate}
              setOpenReportTemplate={setOpenReportTemplate}
              reportData={reportData}
              end_date={end_date}
              companyName={companyName}
            />
          )}
          {/* ledger report if user wants a general ledger item report */}
          {currentReportType === "General Ledger" && (
            <LedgerReport
              openReportTemplate={openReportTemplate}
              setOpenReportTemplate={setOpenReportTemplate}
              reportData={reportData}
              start_date={start_date}
              end_date={end_date}
              companyName={companyName}
            />
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state.auth.user?.id,
    loading: state.shared?.loading,
    reportTypes: state.finance?.reportTypes,
    specificReports: state.finance?.specificReports,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
    getReportTypes: (userId) => dispatch(get_report_types(userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
