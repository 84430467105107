import React, { useState } from "react";
import { connect } from "react-redux";
import MediumDialog from "../../../../../components/common/MediumDialog";
import { add_client_contact_person } from "../../../../../redux/actions/saas_clients";
import { START_LOADING } from "../../../../../redux/actions/types";
import {
  ifEmpty,
  resetFormValues,
} from "../../../../../shared/sharedFunctions";
import CircularProgress from "@mui/material/CircularProgress";

const AddClientContact = (props) => {
  const { openAddClientContact, currentClient, userId, loading } = props;
  const { setOpenAddClientContact, startLoading, addClientContactPerson } =
    props;

  const [contactDetails, setContactDetails] = useState({
    first_name: "",
    last_name: "",
    username: "",
    email: "",
  });

  const { first_name, last_name, username, email } = contactDetails;

  const resetForm = () => {
    resetFormValues(contactDetails);
  };

  const handleClose = () => {
    resetForm();
    setOpenAddClientContact(false);
  };

  const handleChange = (e) => {
    setContactDetails({ ...contactDetails, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (ifEmpty(contactDetails)) {
      return window.alert("All fields are required");
    }
    startLoading();
    const body = {
      ...contactDetails,
      clientId: currentClient?.id,
    };
    addClientContactPerson(userId, body, resetForm);
  };

  return (
    <MediumDialog isOpen={openAddClientContact}>
      <div class="dialog" id={loading ? "formSubmitting" : ""}>
        <h3>Add {currentClient?.company_name} contact person</h3>
        <div class="dialog__row">
          <span>
            <label for="">First Name</label>
            <input
              type="text"
              name="first_name"
              onChange={handleChange}
              value={first_name}
            />
          </span>
          <span>
            <label for="">Last Name</label>
            <input
              type="text"
              name="last_name"
              onChange={handleChange}
              value={last_name}
            />
          </span>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div class="dialog__row">
          <span>
            <label for="">Username</label>
            <input
              type="text"
              name="username"
              onChange={handleChange}
              value={username}
            />
          </span>
          <span>
            <label for="">Email</label>
            <input
              type="email"
              name="email"
              onChange={handleChange}
              value={email}
            />
          </span>
        </div>
        <div class="form__Buttons">
          <button type="button" onClick={handleClose}>
            Close
          </button>
          <button type="button" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    </MediumDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    addClientContactPerson: (userId, body, resetForm) =>
      dispatch(add_client_contact_person(userId, body, resetForm)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddClientContact);
