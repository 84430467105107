import * as actionTypes from "../actions/types";
import * as api from "../../api";

import { showError, stopLoading } from "./shared";

// action to get saas clients
export const get_saas_clients = (userId) => async (dispatch) => {
  await api
    .getSaasClients(userId)
    .then((res) => {
      dispatch({
        type: actionTypes.SET_SAAS_CLIENTS,
        payload: res?.data?.saas_clients_data,
      });
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// action to create a new saas client
export const create_saas_client =
  (userId, body, resetForm) => async (dispatch) => {
    await api
      .createSaasClient(userId, body)
      .then((res) => {
        dispatch({
          type: actionTypes.NEW_SAAS_CLIENT,
          payload: res?.data?.new_saas_client,
        });
        resetForm();
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading(dispatch));
  };

// action to edit client
export const edit_saas_client = (userId, body) => async (dispatch) => {
  await api
    .editSaasClient(userId, body)
    .then((res) => {
      dispatch({
        type: actionTypes.EDIT_SAAS_CLIENT,
        payload: res?.data?.updated_client,
      });
      window.alert(res?.data?.detail);
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// action to add client contact person
export const add_client_contact_person =
  (userId, body, resetForm) => async (dispatch) => {
    await api
      .addClientContactPerson(userId, body)
      .then((res) => {
        dispatch({
          type: actionTypes.ADD_CLIENT_CONTACT,
          payload: res?.data?.updated_client,
        });
        resetForm();
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading(dispatch));
  };
