// import installed packages
// import styles
import "./HomeCard.scss";
// import material ui items
// import shared/global items
// import components/pages
// import redux API

import React from "react";

const HomeCard = (props) => {
  return (
    <div className="home__card">
      <img src={`${props?.image}`} alt="" />
      <h3>{props?.title}</h3>
      <p>{props?.description}</p>
    </div>
  );
};

export default HomeCard;
