// import installed packages
import { useState } from "react";
import { connect } from "react-redux";

// import styles
// import material ui items
import CircularProgress from "@mui/material/CircularProgress";
// import shared/global items
import { ifEmpty, resetFormValues } from "../../../../shared/sharedFunctions";
import globals from "../../../../shared/globals";
// import components/pages
import MinDialog from "../../../../components/common/MinDialog";
// import redux API
import { START_LOADING } from "../../../../redux/actions/types";
import { create_new_ledger_group } from "../../../../redux/actions/finance";

const AddLedgerGroup = (props) => {
  const { parent_ledger_groups, openAddLedgerGroup, userId, loading } = props; // get state from props
  const { startLoading, createNewLedgerGroup, setOpenAddLedgerGroup } = props; // get dispatch actions from props

  const [newLedgerGroup, setNewLedgerGroup] = useState({
    name: "",
    parent_ledger_group: "",
  });

  // destructure
  const { name, parent_ledger_group } = newLedgerGroup;
  const { fillFields } = globals;

  const resetForm = () => {
    resetFormValues(newLedgerGroup);
  };

  const handleClose = () => {
    resetForm();
    setOpenAddLedgerGroup(false);
  };

  const handleChange = (e) => {
    setNewLedgerGroup({ ...newLedgerGroup, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (ifEmpty(newLedgerGroup)) {
      return alert(fillFields);
    }
    startLoading();
    createNewLedgerGroup(userId, newLedgerGroup, resetForm);
  };

  return (
    <MinDialog isOpen={openAddLedgerGroup}>
      <form className="dialog" id={loading ? "formSubmitting" : ""}>
        <h3>Ledger group details</h3>
        <div className="dialog__rowSingleItem">
          <label htmlFor="">Parent Group</label>
          <select
            name="parent_ledger_group"
            onChange={handleChange}
            value={parent_ledger_group}
          >
            <option value="" disabled selected>
              Select parent group
            </option>
            {parent_ledger_groups?.map((parent_group) => (
              <option value={parent_group?.id} key={parent_group?.id}>
                {parent_group?.name}
              </option>
            ))}
          </select>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "42%" }}
          />
        )}
        <div className="dialog__rowSingleItem">
          <label htmlFor="">Ledger Group Name</label>
          <input type="text" name="name" onChange={handleChange} value={name} />
        </div>
        <div className="form__Buttons">
          <button type="button" onClick={handleClose}>
            Close
          </button>
          <button type="submit" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </form>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.shared?.loading,
    parent_ledger_groups: state.finance?.parent_ledger_groups,
    userId: state.auth.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    createNewLedgerGroup: (userId, newLedgerGroup, resetForm) =>
      dispatch(create_new_ledger_group(userId, newLedgerGroup, resetForm)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddLedgerGroup);
