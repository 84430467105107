// user types
export const OPEN_SIGNUP = "OPEN_SIGNUP";
export const CLOSE_SIGNUP = "CLOSE_SIGNUP";
export const OPEN_LOGIN = "OPEN_LOGIN";
export const CLOSE_LOGIN = "CLOSE_LOGIN";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const LOGOUT = "LOGOUT";
export const OPEN_FORGOT_PASSWORD = "OPEN_FORGOT_PASSWORD";
export const CLOSE_FORGOT_PASSWORD = "CLOSE_FORGOT_PASSWORD";
export const OPEN_RESEND_ACTIVATION = "OPEN_RESEND_ACTIVATION";
export const CLOSE_RESEND_ACTIVATION = "CLOSE_RESEND_ACTIVATION";
export const OPEN_PASSWORD_RESET_CONFIRM = "OPEN_PASSWORD_RESET_CONFIRM";
export const CLOSE_PASSWORD_RESET_CONFIRM = "CLOSE_PASSWORD_RESET_CONFIRM";
export const OPEN_CHANGE_PASSWORD = "OPEN_CHANGE_PASSWORD";
export const CLOSE_CHANGE_PASSWORD = "CLOSE_CHANGE_PASSWORD";

// shared types
export const START_LOADING = "START_LOADING";
export const STOP_LOADING = "STOP_LOADING";

// email types
export const SET_EMAILS = "SET_EMAILS";
export const TOGGLE_EMAIL = "TOGGLE_EMAIL";
export const TOGGLE_COMPOSE_EMAIL = "TOGGLE_COMPOSE_EMAIL";
export const NEW_SENT_EMAIL = "NEW_SENT_EMAIL";
export const UPDATE_READ_EMAIL = "UPDATE_READ_EMAIL";
export const SET_ACTIVE_EMAIL_COMPONENT = "SET_ACTIVE_EMAIL_COMPONENT";
export const DELETE_EMAIL = "DELETE_EMAIL";

// saas clients types
export const NEW_SAAS_CLIENT = "NEW_SAAS_CLIENT";
export const EDIT_SAAS_CLIENT = "EDIT_SAAS_CLIENT";
export const SET_SAAS_CLIENTS = "SET_SAAS_CLIENTS";
export const ADD_CLIENT_CONTACT = "ADD_CLIENT_CONTACT";

// finance types
export const NEW_LEDGER_GROUP = "NEW_LEDGER_GROUP";
export const EDIT_LEDGER_GROUP = "EDIT_LEDGER_GROUP";
export const NEW_LEDGER = "NEW_LEDGER";
export const EDIT_LEDGER = "EDIT_LEDGER";
export const DELETE_LEDGER_OR_GROUP = "DELETE_LEDGER_OR_GROUP";
export const SET_LEDGERS = "SET_LEDGERS";
export const SET_REPORTS = "SET_REPORTS";
