// import installed packages
import { Link } from "react-router-dom";
// import styles
import "./Footer.scss";
// import material ui items
// import shared/global items
import azw_logo from "../../../images/azw_logo.png";
// import components/pages
// import redux API

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer__first">
        <div className="footer__logo">
          <img src={azw_logo} alt="" />
          <h1>AZW Group</h1>
        </div>
        <p>Bringing the best in you.</p>
      </div>
      <div className="footer__second">
        <h2>Quick Links</h2>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/about/about-us/">About Us</Link>
          </li>
          <li>
            <Link to="/services/mobile-apps/">Mobile Apps</Link>
          </li>
          <li>
            <Link to="/services/web-apps/">Web Apps</Link>
          </li>
          <li>
            <Link to="/contact/contact-us/">Contact Us</Link>
          </li>
        </ul>
      </div>
      <div className="footer__third">
        <h2>Contact Us</h2>
        <ul>
          <li>Kiambaa, Nakuru-Naivasha Highway</li>
          <li>Nairobi, Kenya</li>
          <li>info@azwgroup.com</li>
        </ul>
      </div>
      <div className="footer__fourth">
        <h2>Stay Updated</h2>
        <ul>
          <li>Stay informed with our periodic newsletter.</li>
          <li>
            <form>
              <input type="email" name="" placeholder="Enter your email" />
              <button type="button">
                <i className="bx bxs-send"></i>
              </button>
            </form>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Footer;
