// import installed packages
// import styles
// import material ui items
// import shared/global items
// import components/pages
// import redux API

const ClientLinks = ({ Link, pathname, toggleSubmenu, openSubMenu }) => {
  return (
    <>
      <Link
        to="/clients/my-licenses/"
        className={
          `${pathname}` === "/clients/my-licenses/"
            ? "nav__link active"
            : "nav__link"
        }
      >
        <i className="bx bxs-cart-alt"></i>
        <span>My Licenses</span>
      </Link>
    </>
  );
};

export default ClientLinks;
