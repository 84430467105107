// import installed packages
import { useDispatch } from "react-redux";
// import styles
// import material ui items
// import shared/global items
// import components/pages
// import redux API
import { TOGGLE_COMPOSE_EMAIL } from "../../../../redux/actions/types";

const StaffLinks = ({ Link, pathname, toggleSubmenu, openSubMenu }) => {
  const dispatch = useDispatch();

  const toggleComposeEmail = () => {
    dispatch({
      type: TOGGLE_COMPOSE_EMAIL,
      payload: { new_or_reply: "new", isOpen: true },
    });
  };
  return (
    <>
      <Link
        to="#"
        onClick={() => toggleSubmenu("Emails")}
        className={`${pathname}` === "#" ? "nav__link active" : "nav__link"}
      >
        <i className="bx bxs-envelope"></i>
        <span className="nav__name">Emails</span>
      </Link>
      {openSubMenu === "Emails" && (
        <>
          <Link
            to="#"
            className={
              `${pathname}` === "#"
                ? "nav__link active sidebar__submenu"
                : "nav__link sidebar__submenu"
            }
          >
            <span className="nav__name" onClick={toggleComposeEmail}>
              &bull; Compose
            </span>
          </Link>
          <Link
            to="/emails/inbox/"
            className={
              `${pathname}` === "/emails/inbox/"
                ? "nav__link active sidebar__submenu"
                : "nav__link sidebar__submenu"
            }
          >
            <span className="nav__name">&bull; Inbox</span>
          </Link>
          <Link
            to="/emails/sent/"
            className={
              `${pathname}` === "/emails/sent/"
                ? "nav__link active sidebar__submenu"
                : "nav__link sidebar__submenu"
            }
          >
            <span className="nav__name">&bull; Sent</span>
          </Link>
        </>
      )}
    </>
  );
};

export default StaffLinks;
