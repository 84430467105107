import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import MaxDialog from "../../../../components/common/MaxDialog";
import { delete_ledger_or_group } from "../../../../redux/actions/finance";
import { START_LOADING } from "../../../../redux/actions/types";
import EditLedger from "./EditLedger";
import EditLedgerGroup from "./EditLedgerGroup";
import CircularProgress from "@mui/material/CircularProgress";

const ShowLedgers = (props) => {
  const {
    openShowLedgers,
    ledgerType,
    ledgers,
    ledger_groups,
    userId,
    loading,
  } = props;
  const {
    setOpenShowLedgers,
    setLedgerType,
    startLoading,
    deleteLedgerOrGroup,
  } = props;

  const [currentLedgerItem, setCurrentLedgerItem] = useState({});
  const [openEditLedger, setOpenEditLedger] = useState(false);
  const [openEditLedgerGroup, setOpenEditLedgerGroup] = useState(false);
  const [ledgerItems, setLedgerItems] = useState([]);

  useEffect(() => {
    if (ledgerType === "Ledgers") {
      setLedgerItems(ledgers);
    } else if (ledgerType === "Ledger Groups") {
      setLedgerItems(ledger_groups);
    }
  }, [ledgerType, ledger_groups, ledgers]);

  const handleClose = () => {
    setOpenShowLedgers(false);
    setLedgerType("");
  };

  const handleOpenEdit = (item) => {
    setCurrentLedgerItem(item);
    if (ledgerType === "Ledgers") {
      setOpenEditLedger(true);
    } else if (ledgerType === "Ledger Groups") {
      setOpenEditLedgerGroup(true);
    }
  };

  const handleDeleteLedgerOrGroup = (e, itemId, ledgerOrGroup) => {
    e.preventDefault();
    if (
      window.confirm(
        `Delete action is not reversible. Are you sure you want to delete this ${ledgerOrGroup}`
      )
    ) {
      startLoading();
      deleteLedgerOrGroup(userId, itemId, ledgerOrGroup);
    }
  };

  return (
    <>
      <MaxDialog isOpen={openShowLedgers} maxWidth="1200px" maxHeight="90vh">
        <div className="dialog" id={loading ? "formSubmitting" : ""}>
          <h3>Existing {ledgerType}</h3>
          <p className="tc">
            Note:{" "}
            <span className="red ud">System ledgers are not editable</span>
          </p>
          <table className="table__listing">
            <tr className="table__listingHeader">
              <th>Name</th>
              <th>Ledger Class</th>
              <th>Edit</th>
              <th className="tc">Delete</th>
            </tr>
            {loading && (
              <CircularProgress
                style={{ position: "absolute", marginLeft: "45%" }}
              />
            )}
            {ledgerItems?.length > 0 ? (
              <>
                {ledgerItems?.map((item) => (
                  <tr className="table__listingItem" key={item?.id}>
                    <td>{item?.name}</td>
                    <td>{item?.ledger_class}</td>
                    <td>
                      {item?.is_system_ledger ? (
                        <span className="purple bd">N/A</span>
                      ) : (
                        <span
                          className="green bd button__sp"
                          onClick={() => handleOpenEdit(item)}
                        >
                          Edit
                        </span>
                      )}
                    </td>
                    <td className="tc">
                      {item?.is_system_ledger ? (
                        <span className="purple bd">N/A</span>
                      ) : (
                        <i
                          className="bx bxs-trash-alt red bd button__sp"
                          onClick={(e) =>
                            handleDeleteLedgerOrGroup(
                              e,
                              item?.id,
                              ledgerType === "Ledgers" ? "ledger" : "group"
                            )
                          }
                        ></i>
                      )}
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <h4 className="not__available">No items available</h4>
            )}
          </table>
          <div className="form__Buttons">
            <button type="button" onClick={handleClose}>
              Close
            </button>
          </div>
        </div>
      </MaxDialog>
      {/* child components */}
      {openEditLedger && (
        <EditLedger
          currentLedgerItem={currentLedgerItem}
          setCurrentLedgerItem={setCurrentLedgerItem}
          openEditLedger={openEditLedger}
          setOpenEditLedger={setOpenEditLedger}
        />
      )}
      {openEditLedgerGroup && (
        <EditLedgerGroup
          currentLedgerItem={currentLedgerItem}
          setCurrentLedgerItem={setCurrentLedgerItem}
          openEditLedgerGroup={openEditLedgerGroup}
          setOpenEditLedgerGroup={setOpenEditLedgerGroup}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    ledger_groups: state.finance?.ledger_groups,
    ledgers: state.finance?.ledgers,
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    deleteLedgerOrGroup: (userId, itemId, ledgerOrGroup) =>
      dispatch(delete_ledger_or_group(userId, itemId, ledgerOrGroup)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowLedgers);
