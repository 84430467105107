// material ui items
import Dialog from "@mui/material/Dialog";
import Draggable from "react-draggable";
import DialogTitle from "@mui/material/DialogTitle";

const MediumDialog = (props) => {
  return (
    <Draggable handle=".draggable-area">
      <Dialog
        open={props.isOpen}
        maxWidth="md"
        style={{
          maxWidth: props?.maxWidth,
          margin: "auto",
        }}
        fullWidth
        hideBackdrop
        disableEnforceFocus
        disableBackdropClick
      >
        {/* we use this to enable dragging of components */}
        <DialogTitle
          style={{ cursor: "move" }}
          classes={{ root: "draggable-area" }}
        ></DialogTitle>
        {props.children}
      </Dialog>
    </Draggable>
  );
};

export default MediumDialog;
