import React, { useState } from "react";
import { connect } from "react-redux";
import MinDialog from "../../../../../components/common/MinDialog";
import { showError } from "../../../../../redux/actions/shared";
import CircularProgress from "@mui/material/CircularProgress";
import {
  START_LOADING,
  STOP_LOADING,
} from "../../../../../redux/actions/types";
import API from "../../../../../shared/axios";
import {
  ifEmpty,
  resetFormValues,
} from "../../../../../shared/sharedFunctions";

const GenerateClientLicense = (props) => {
  const { openGenerateLicense, currentClient, userId, licenses, loading } =
    props;
  const { setOpenGenerateLicense, startLoading, stopLoading, setLicenses } =
    props;

  const [licenseDetails, setLicenseDetails] = useState({
    start_date: "",
    license_type: "",
    license_duration: "",
  });

  const { start_date, license_type, license_duration } = licenseDetails;

  const handleChange = (e) => {
    setLicenseDetails({ ...licenseDetails, [e.target.name]: e.target.value });
  };

  const resetForm = () => {
    resetFormValues(licenseDetails);
  };

  const handleClose = () => {
    resetForm();
    setOpenGenerateLicense(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (ifEmpty(licenseDetails)) {
      return window.alert("All fields are required");
    }
    startLoading();
    const body = {
      ...licenseDetails,
      clientId: currentClient?.id,
    };
    await API.post(
      `/api/azw-saas/admin/maintain-client-licenses/${userId}/${currentClient?.id}/`,
      body
    )
      .then((res) => {
        setLicenses([...licenses, res?.data?.new_license]);
        resetForm();
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  return (
    <MinDialog isOpen={openGenerateLicense}>
      <div class="dialog" id={loading ? "formSubmitting" : ""}>
        <h3>Enter license details for {currentClient?.company_name}</h3>
        <div class="dialog__rowSingleItem">
          <label for="">License Type</label>
          <select
            name="license_type"
            onChange={handleChange}
            value={license_type}
          >
            <option value="" disabled selected>
              --select--
            </option>
            <option value="trial_license">Trial License</option>
            <option value="full_license">Full License</option>
          </select>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div class="dialog__rowSingleItem">
          <label for="">Start Date</label>
          <input
            type="date"
            name="start_date"
            onChange={handleChange}
            value={start_date}
          />
        </div>
        <div class="dialog__rowSingleItem">
          <label for="">License Duration</label>
          <select
            name="license_duration"
            onChange={handleChange}
            value={license_duration}
          >
            <option value="" disabled selected>
              --select--
            </option>
            <option value="7">1 Week</option>
            <option value="30">1 Month</option>
            <option value="60">2 Months</option>
            <option value="90">3 Months</option>
          </select>
        </div>
        <div class="form__Buttons">
          <button type="button" onClick={handleClose}>
            Close
          </button>
          <button type="button" onClick={handleSubmit}>
            Generate
          </button>
        </div>
      </div>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GenerateClientLicense);
