// import installed packages
import React from "react";
import { connect } from "react-redux";
import parse from "html-react-parser";
// import styles
import "./EmailDetails.scss";
// import material ui items
// import shared/global items
import MaxDialog from "../../../components/common/MaxDialog";

// import components/pages
import ComposeEmail from "./ComposeEmail";
// import redux API
import {
  TOGGLE_COMPOSE_EMAIL,
  TOGGLE_EMAIL,
} from "../../../redux/actions/types";

const EmailDetails = (props) => {
  const { email, openEmail, activeEmailComponent } = props;
  const { closeEmailDetails, openComposeEmail } = props;
  return (
    <>
      <MaxDialog isOpen={openEmail} maxWidth="1400px">
        <div className="dialog">
          <h4>Subject: {email?.subject}</h4>
          <p>
            {activeEmailComponent === "inbox" ? (
              <>
                <strong>From:</strong> {email?.sender_email}
              </>
            ) : (
              <>
                <strong>To:</strong> {email?.email_to}
              </>
            )}
          </p>
          <br />
          <div>{parse(`${email?.message}`)}</div>
          <br />

          <div className="email_actions">
            {activeEmailComponent === "inbox" && (
              <button
                type="button"
                className="add__button"
                onClick={openComposeEmail}
              >
                Reply
              </button>
            )}
          </div>

          <br />
          <hr />
          <div className="form__Buttons">
            <button type="button" onClick={closeEmailDetails}>
              Close
            </button>
          </div>
        </div>
      </MaxDialog>
      {/* child components */}
      <ComposeEmail />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    openEmail: state.emails?.openEmail,
    email: state?.emails?.currentEmail,
    activeEmailComponent: state?.emails?.activeEmailComponent,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeEmailDetails: () =>
      dispatch({
        type: TOGGLE_EMAIL,
        payload: { openEmail: false, email: {} },
      }),
    openComposeEmail: () =>
      dispatch({
        type: TOGGLE_COMPOSE_EMAIL,
        payload: { new_or_reply: "reply", isOpen: true },
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailDetails);
