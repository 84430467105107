import API from "../shared/axios";

// patch user data
export const updateUser = (updatedUser, userId) =>
  API.patch(`/api/user/update-user-details/${userId}/`, updatedUser);

// emails actions
export const updateReadEmail = (userId, emailId) =>
  API.patch(`/api/appemail/update-read-email/${userId}/${emailId}/`, {});

// PATCH REQUEST API to edit ledger group
export const editLedgerGroup = (userId, body) =>
  API.patch(`/api/finance/maintain-ledger-groups/${userId}/`, body);

// PATCH REQUEST API to edit ledger
export const editLedger = (userId, body) =>
  API.patch(`/api/finance/maintain-ledgers/${userId}/`, body);

// PATCH REQUEST to edit client
export const editSaasClient = (userId, body) =>
  API.patch(`/api/azw-saas/admin/maintain-saas-clients/${userId}/`, body);
