// import installed packages
import React, { useEffect } from "react";
import { connect } from "react-redux";
// import styles
import "./InboxEmails.scss";
// import material ui items
// import shared/global items
// import components/pages
import EmailsListing from "./components/EmailsListing";
// import redux API
import { get_my_sent_or_received_emails } from "../../redux/actions/emails";
import { START_LOADING } from "../../redux/actions/types";
import CircularProgress from "@mui/material/CircularProgress";

const InboxEmails = (props) => {
  const { loading, received, userId } = props;
  const { getMySentOrReceivedEmails, startLoading } = props;
  useEffect(() => {
    startLoading();
    getMySentOrReceivedEmails(userId);
  }, [getMySentOrReceivedEmails, startLoading, userId]);
  return (
    <div className="inbox">
      {loading && (
        <CircularProgress style={{ position: "absolute", marginLeft: "45%" }} />
      )}
      {received?.length > 0 ? (
        <EmailsListing emails={received} />
      ) : (
        <h4 className="not__available">No emails received yet</h4>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    received: state?.emails?.received,
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    getMySentOrReceivedEmails: (userId) =>
      dispatch(get_my_sent_or_received_emails(userId, "received")),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InboxEmails);
