// import installed packages
// import styles
import "./Products.scss";
// import material ui items
// import shared/global items
import online_learning from "../../../../images/online_learning.png";
import school_system from "../../../../images/school_system.jpg";
import online_tutoring from "../../../../images/online_tutoring.jpg";
// import components/pages
// import redux API

import React from "react";
import HomeCard from "../HomeCard/HomeCard";

const Products = () => {
  return (
    <div className="products">
      <h3>Featured Products</h3>
      <h2>Creating connections for like minds to advance concepts.</h2>
      <div className="featured__products">
        <HomeCard
          title="Funzo Letu"
          image={school_system}
          description="Integrated school management system with a click."
        />
        <HomeCard
          title="Mafunzo"
          image={online_tutoring}
          description="Online live tutoring platform with professional tutors to sharpen your child skills."
        />
        <HomeCard
          title="CourZe Hub"
          image={online_learning}
          description="an online platform that eases acquisition of skills from industry experts"
        />
      </div>
    </div>
  );
};

export default Products;
