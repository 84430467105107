import { updateObject } from "../utility";
import * as actionTypes from "../actions/types";

const initialState = {
  received: [],
  sent: [],
  openEmail: false,
  currentEmail: {},
  openComposeEmailStatus: {
    new_or_reply: "", // defines if new email or reply email since the same component is used
    isOpen: false,
  },
  activeEmailComponent: "inbox",
};

const setMySentOrReceivedEmails = (state, payload) => {
  if (payload.sent_or_received === "sent") {
    return updateObject(state, {
      sent: payload.emails_data,
    });
  } else if (payload.sent_or_received === "received") {
    return updateObject(state, {
      received: payload.emails_data,
    });
  }
};

const toggleEmailDetails = (state, payload) => {
  return updateObject(state, {
    openEmail: payload.openEmail,
    currentEmail: payload.email,
  });
};

const toggleComposeEmail = (state, payload) => {
  return updateObject(state, {
    openComposeEmailStatus: {
      new_or_reply: payload.new_or_reply,
      isOpen: payload.isOpen,
    },
  });
};

const newSentEmail = (state, payload) => {
  return updateObject(state, {
    sent: [...state.sent, payload.new_sent_email],
    openComposeEmailStatus: { isOpen: payload.toggle_compose_email },
  });
};

const updateReadEmail = (state, payload) => {
  return updateObject(state, {
    received: state.received.map((email) =>
      email.id === payload ? { ...email, is_read: true } : email
    ),
  });
};

const setActiveEmailComponent = (state, payload) => {
  return updateObject(state, {
    activeEmailComponent: payload,
  });
};

const deleteEmail = (state, payload) => {
  if (payload?.email_type === "inbox") {
    return updateObject(state, {
      received: state.received.filter((email) => email.id !== payload?.emailId),
    });
  }
  if (payload?.email_type === "sent") {
    return updateObject(state, {
      sent: state.sent.filter((email) => email.id !== payload?.emailId),
    });
  }
};

const emailsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_EMAILS:
      return setMySentOrReceivedEmails(state, payload);
    case actionTypes.TOGGLE_EMAIL:
      return toggleEmailDetails(state, payload);
    case actionTypes.TOGGLE_COMPOSE_EMAIL:
      return toggleComposeEmail(state, payload);
    case actionTypes.NEW_SENT_EMAIL:
      return newSentEmail(state, payload);
    case actionTypes.UPDATE_READ_EMAIL:
      return updateReadEmail(state, payload);
    case actionTypes.SET_ACTIVE_EMAIL_COMPONENT:
      return setActiveEmailComponent(state, payload);
    case actionTypes.DELETE_EMAIL:
      return deleteEmail(state, payload);
    default:
      return state;
  }
};

export default emailsReducer;
